import { isStorageAvailable } from '../common/utils/isStorageAvailable';

const LOCALSTORAGE_KEY = 'giftbox-debug';
const LOG_PREFIX = 'GiftBox: ';
let loggingEnabled = false;
if (isStorageAvailable()) {
  loggingEnabled = localStorage.getItem(LOCALSTORAGE_KEY) === 'true';
}

window.giftBoxEnableDebug = () => {
  if (isStorageAvailable()) {
    localStorage.setItem(LOCALSTORAGE_KEY, 'true');
  }
  loggingEnabled = true;
  logInfo('Enabled console output 🔧');
};

export function logError(error: unknown | string, ...rest: unknown[]) {
  if (error instanceof Error) {
    const errorString = String(error);
    const errorMessage = `${LOG_PREFIX + errorString}`;
    console.error(errorMessage, ...rest);
  } else {
    console.error('Unknown error:', error);
  }
}

export function logInfo(message: string, ...rest: unknown[]) {
  if (!loggingEnabled) {
    return;
  }

  const prefixedMessage =
    typeof message === 'string' ? LOG_PREFIX + message : message;
  console.info(prefixedMessage, ...rest);
}
