import { GhostProductHandlePairs } from '../domain';
import { logInfo } from './logging';

const isProductPage = () => {
  const urlPath = window.location.pathname;
  return urlPath.includes('/products/');
};

// Logic reused from Candy Rack
const getProductHandle = (customUrl?: string) => {
  let productHandle = '';
  const testedUrl = customUrl || window.location.pathname;
  const productHandleMatch = testedUrl.match(/.*\/products\/([^?]+)(\?.*)?/);
  if (productHandleMatch) {
    productHandle = productHandleMatch[1];
  }
  return productHandle;
};

const redirectToParentOrHomepage = (parentHandle: string | null) => {
  // Redirect to homepage in case parent handle is null or ''
  if (parentHandle === null || parentHandle.trim() === '') {
    logInfo('Redirecting to home page, parent not found');
    window.location.href = '/';
  } else {
    logInfo('Redirecting to parent:', parentHandle);
    window.location.href = `${window.location.origin}/products/${parentHandle}`;
  }
};

export const handleRedirectToParent = (
  handlePairs: GhostProductHandlePairs,
) => {
  if (isProductPage()) {
    const currentHandle = getProductHandle();
    const parentHandle = handlePairs[currentHandle];
    // Undefined only in case we dont find any match
    if (parentHandle !== undefined) {
      redirectToParentOrHomepage(parentHandle);
    }
  }
};

// Creating document level listener to change redirects of anchors to parent in case of GP
export const createEventListenerRedirect = (
  handlePairs: GhostProductHandlePairs,
) => {
  document.addEventListener('click', (e) => {
    // TS workaround
    const target = e.target as HTMLElement;

    // Checking if anchor or element within an anchor was clicked
    const isAnchor = target.tagName.toLowerCase() === 'a';
    const wrapperAnchor = target.closest('a');

    if (isAnchor || wrapperAnchor) {
      const anchor = (isAnchor ? target : wrapperAnchor) as HTMLAnchorElement;

      const handle = getProductHandle(anchor.href);
      const parentHandle = handlePairs[handle];
      // Undefined only in case we dont find any match
      if (parentHandle !== undefined) {
        e.preventDefault();
        redirectToParentOrHomepage(parentHandle);
      }
    }
  });
};
