import { Cart } from '../domain';
import { getApiRequestConfig, parseJson } from './index';
import { getCookie } from '../utils/cookies';

export function getDiscountCodeFromStorageOrFallbackToCookie() {
  let code = sessionStorage.getItem('giftbox-discount');
  if (!code) { // Only temporary for transition period
    code = localStorage.getItem('giftbox-discount');
  }
  if (!code) {
    code = getCookie('discount_code');
  }
  return code;
}

export function validateDiscountCode(discountCode: string) {
  const hostname = process.env.REACT_APP_API_HOSTNAME;
  // need to be encoded in case "+" char is included in the discount code
  const encodedDiscountCode = encodeURIComponent(discountCode);
  return parseJson(
    fetch(
      `${hostname}api/validate-discount?shop=${window.Shopify.shop}&discount_code=${encodedDiscountCode}`,
      getApiRequestConfig(),
    ),
  );
}

export async function getDiscountSpendings(discountCode: string, cart: Cart) {
  const hostname = process.env.REACT_APP_API_HOSTNAME;
  const data = {
    shop: window.Shopify.shop,
    cart: cart,
    discount_code: discountCode,
  };

  if (discountCode && cart.item_count > 0) {
    return parseJson(
      fetch(`${hostname}api/discount-spendings`, {
        ...getApiRequestConfig('POST'),
        body: JSON.stringify(data),
      }),
    );
  }
  return cart;
}
