import { Cart, CollectionsInCart, Offer } from "../../domain";
import { getCartPrice } from "./helpers";
import { getCartCollectionProductsSpend } from "./products";

export function isCoveredProductsInCart(offer: Offer, cart: Cart) {
  if (offer.threshold_mode !== "product_added") {
    return false;
  }

  // As we know that cart items are normaly available (otherwise they wouldn't have been added to the cart),
  // we can skip loading targetProducts (which would lead to big architectural changes) to fully to check their availability
  const items = cart.items.filter(
    (item) =>
      item.product_id === offer.target_shopify_product_id &&
      (offer.target_all_variants_selected ||
        offer.target_shopify_product_variants.includes(item.variant_id))
  );
  let quantity = 0;
  items.forEach((item) => {
    quantity += item.quantity;
  });
  return quantity >= offer.target_min_quantity;
}

export function isCoveredCollectionsInCart(
  offer: Offer,
  cart: Cart,
  collectionsInCart: CollectionsInCart
) {
  if ("collection_added" !== offer.threshold_mode) {
    return false;
  }

  const collectionProductsInCart =
    collectionsInCart[offer.target_shopify_collection_id] || [];
  const cartProductsWithCollection = cart.items.filter(
    (item) =>
      collectionProductsInCart.includes(item.product_id) &&
      item.product_type !== "giftbox_ghost_product"
  );
  let cartCollectionProductsQuantity = 0;

  for (const item of cartProductsWithCollection) {
    cartCollectionProductsQuantity += item.quantity;
  }

  return cartCollectionProductsQuantity >= offer.target_min_quantity;
}

export function isCoveredCollectionsInCartWithSpend(
  offer: Offer,
  cart: Cart,
  collectionsInCart: CollectionsInCart
) {
  if ("spend_in_collection" !== offer.threshold_mode) {
    return false;
  }

  return (
    getCartCollectionProductsSpend(offer, cart, collectionsInCart) >=
    (offer?.threshold ?? -1)
  );
}

export function isCoveredThresholdInCart(offer: Offer, cart: Cart) {
  if ("free_from" !== offer.threshold_mode) {
    return false;
  }

  const cartTotalPrice = getCartPrice(cart);

  return offer.threshold && cartTotalPrice >= offer.threshold;
}
