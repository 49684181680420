import { getCart, getOffers, updateCart } from "../../api";
import { getGhostProductsVariants } from "../../core/products";
import { Cart, CartUpdates, OfferStorage } from "../../domain";
import isFunction from "../../utils/isFunction";
import { logInfo } from "../../utils/logging";
import updateDrawerCarts from "../../utils/updateDrawerCarts";
import { isCartPage } from "../cart";
import cartWatcher from "../cart-watcher";
import { findNumberInNodes, updateCustomSelectors } from "../helpers";
import {
  sectionsWithSubelements,
  selectorsCartLink,
  selectorsCartRemoveLink,
  selectorsSectionToUpdate,
  supportedThemesList,
} from "../selectors";

export async function updateCartLineItems(updates: CartUpdates) {
  const offers = await getOffers(window.Shopify.shop);
  const ghostProductsVariants = getGhostProductsVariants(offers);

  const previousCart = await getCart();
  const currentCart = await updateCart(updates);

  const itemsInCartCount = currentCart.items.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  );
  updateCartItemsCount(itemsInCartCount);

  if (gpInCartChanged(ghostProductsVariants, previousCart, currentCart)) {
    if (!isCartPage()) {
      updateDrawerCarts(currentCart);
    }
    await updateCartDomOrReloadPage();
  }
}

export async function isCartUpdated() {
  if (window.GIFTBOX_DISABLE_SECTIONS_UPDATE_AND_SKIP_PAGE_RELOAD && !isCartPage()) {
    // Note this doesn't seem ok for cart page. Reason is gifts could be removed,
    // so the cart would need to be updated.
    logInfo(
      "Page sections update disabled by GIFTBOX_DISABLE_SECTIONS_UPDATE_AND_SKIP_PAGE_RELOAD, not reloading the page."
    );
    return true;
  }

  // To completely disable page sections update set the window.GIFTBOX_DISABLE_SECTIONS_UPDATE to true.
  if (window.GIFTBOX_DISABLE_SECTIONS_UPDATE) {
    logInfo(
      "Page sections update disabled by GIFTBOX_DISABLE_SECTIONS_UPDATE."
    );
    return false;
  }

  const supportedThemes = updateCustomSelectors(
    supportedThemesList,
    "GIFTBOX_SUPPORTED_THEMES_LIST"
  );

  const isTemplateSupported = supportedThemes.includes(
    window.Shopify.theme.name
  );

  if (isCartPage() && !isTemplateSupported) {
    return false;
  }

  try {
    let cartUpdated = false;

    const allSelectorsSectionToUpdate = updateCustomSelectors(
      selectorsSectionToUpdate,
      "GIFTBOX_CUSTOM_SECTION_TO_UPDATE_SELECTORS"
    );
    // Main section should be updated only on cart page to prevent breaking JS on product pages
    if(isCartPage()) {
      allSelectorsSectionToUpdate.push('[id*="shopify-section-template"][id*="__main"]')
    }
    for (let index = 0; index < allSelectorsSectionToUpdate.length; index++) {
      const selector = allSelectorsSectionToUpdate[index];
      const sectionElements = document.querySelectorAll(selector);
      for (let j = 0; j < sectionElements.length; j++) {
        const sectionElement = sectionElements[j];
        let sectionName = sectionElement.id.replace("shopify-section-", "");
        if (!sectionName) {
          sectionName = sectionElement.tagName.toLowerCase();
        }

        let response = await fetch(
          window.location.pathname + "?sections=" + sectionName
        );
        let json = await response.json();

        if (json[sectionName]) {
          const placeholder = document.createElement("div");
          placeholder.innerHTML = json[sectionName];

          const allSectionsWithSubelements = updateCustomSelectors(
            sectionsWithSubelements,
            "GIFTBOX_CUSTOM_SECTIONS_WITH_SUBELEMENTS"
          );
          if (allSectionsWithSubelements.includes(sectionName)) {
            const subelement = placeholder.getElementsByTagName(sectionName)[0];
            if (subelement) {
              subelement.classList = sectionElement.classList;
              sectionElement.replaceWith(subelement);
            }
          } else {
            sectionElement.replaceWith(placeholder.firstChild);
          }

          if (
            sectionName.includes("cart-items") ||
            sectionName.includes("cart-template") ||
            sectionName.includes("__main")
          ) {
            cartUpdated = true;
          }
        }
      }
    }

    if (isCartPage() && !cartUpdated) {
      return false;
    }
  } catch (err) {
    console.log(`update cart err: ${err}`);
  }

  return true;
}

export function gpInCartChanged(
  ghostProductsVariants: number[],
  previousCart: Cart,
  currentCart: Cart
) {
  if (previousCart && currentCart) {
    if (previousCart["items"].length !== currentCart["items"].length) {
      return true;
    }

    for (let i = 0; i < previousCart["items"].length; i++) {
      const previousCartItem = previousCart["items"][i];
      const isPreviousCartItemGP = ghostProductsVariants.includes(
        previousCartItem.variant_id
      );
      if (isPreviousCartItemGP) {
        let variantFound = false;
        for (let j = 0; j < currentCart["items"].length; j++) {
          const currentCartItem = currentCart["items"][j];
          if (previousCartItem.variant_id === currentCartItem.variant_id) {
            variantFound = true;
            if (previousCartItem.quantity !== currentCartItem.quantity) {
              return true;
            }
          }
        }
        if (!variantFound) {
          return true;
        }
      }
    }

    return false;
  }

  return true;
}

export function updateCartItemsCount(itemsCount: number) {
  const cartLinkNodes = updateCustomSelectors(
    selectorsCartLink,
    "GIFTBOX_CART_ITEMS_COUNT_SELECTORS"
  );

  const elementsWithCartCount: Element[] = [];
  document.querySelectorAll(cartLinkNodes.join(",")).forEach((cartLink) => {
    findNumberInNodes(cartLink, elementsWithCartCount);
  });

  elementsWithCartCount.forEach((element) => {
    if (!element?.firstChild?.nodeValue) return;
    element.firstChild.nodeValue = String(itemsCount);
  });
}

export async function updateCartDomOrReloadPage() {
  const cartUpdated = await isCartUpdated();
  if (!cartUpdated) {
    window.location.reload();
  }
  cartWatcher.setupDefaultObservers();
}

export function detectGhostProductItem(
  cartItem: Element,
  addedOffers: OfferStorage[]
) {
  const allSelectorsCartRemoveLink = updateCustomSelectors(
    selectorsCartRemoveLink,
    "GIFTBOX_CUSTOM_CART_REMOVE_LINK_SELECTORS"
  );
  const links = cartItem.querySelectorAll(allSelectorsCartRemoveLink.join(","));

  for (let i = 0; i < addedOffers.length; i++) {
    const offer = addedOffers[i];
    const shopifyVariantId = offer.shopifyVariantId;

    if (isFunction(window.GIFTBOX_CUSTOM_GHOST_PRODUCT_VARIANT)) {
      const isVariant = window.GIFTBOX_CUSTOM_IS_GHOST_PRODUCT_VARIANT(
        offer,
        cartItem
      );
      if (isVariant) {
        return shopifyVariantId;
      }
    }

    if (shopifyVariantId) {
      for (let index = 0; index < cartItem.attributes.length; index++) {
        const attribute = cartItem.attributes[index];
        if (attribute.value.includes(shopifyVariantId.toString())) {
          return shopifyVariantId;
        }
      }
      for (let index = 0; index < links.length; index++) {
        const link = links[index];
        if (link.href.includes(shopifyVariantId)) {
          return shopifyVariantId;
        }
      }
    }
  }

  return null;
}
