import { isVisible } from '../../dom';
import {
  AddEligibleOffersToCartArgs,
  addEligibleOffersToCart,
  debouncedAddEligibleOffersToCart,
} from './cartManipulation';

export const makeOfferMutationCallback = (
  args: AddEligibleOffersToCartArgs,
) => {
  addEligibleOffersToCart(args);
  return (mutationsList: MutationRecord[]) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.removedNodes.forEach((node: HTMLFormElement['value']) => {
          if (
            node.action &&
            (node.action.indexOf('/cart') > -1 ||
              node.action.indexOf('/checkout') > -1 ||
              node.action.indexOf('/a/checkout') > -1)
          ) {
            debouncedAddEligibleOffersToCart(args);
          }
        });
        mutation.addedNodes.forEach((node: HTMLFormElement['value']) => {
          if (
            node.querySelector &&
            node.querySelector(
              'form[action*="/cart"],form[action*="/checkout"],[type=submit]',
            )
          ) {
            debouncedAddEligibleOffersToCart(args);
          }

          const checkoutButton = document.querySelector(
            '[href$="checkout"]:not(.giftbox-checkout)',
          ) as HTMLElement;
          if (checkoutButton && isVisible(checkoutButton)) {
            debouncedAddEligibleOffersToCart(args);
          }
        });
      } else if (mutation.type === 'attributes') {
        debouncedAddEligibleOffersToCart(args);
      }
    });
  };
};
