export const ADDED_OFFERS_KEY = 'giftbox-added-offers';
export const ADDED_OFFERS_PREVIOUS_ITERATION_KEY = 'giftbox-added-offers-previous-iteration';
export const SETTINGS_KEY = 'giftbox-settings';

/*
 * Eligible offers that were added to the cart, thus fulfilling relevant conditions
 */
export const JUST_ADDED_OFFERS_KEY = 'giftbox-just-added-offers';
export const FIRST_VISIT_KEY = 'giftbox-first-visit';

/*
 * Used so that we know if we should open the modal after an offer becomes eligible
 */
export const OPEN_MODAL_AFTER_RECEIVING_GIFT_KEY = 'giftbox-open-modal-after-receiving-gift';
