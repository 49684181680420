import { Settings } from '../../../domain';
import { SETTINGS_KEY } from './keys';

export function getSettings(): Settings {
  const data = localStorage.getItem(SETTINGS_KEY);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return {};
    }
  }
  return {};
}
