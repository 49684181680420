// Can be updated using window.GIFTBOX_CUSTOM_CART_ITEMS_SELECTORS.
export const selectorsCartItems = [
  '.cart-item',
  '.cart__row:not(.cart__row--heading)',
  '.cart__table-row',
  '.cart-drawer__item',
  '.cart-row',
  '.ajaxcart__row',
  '.line-item',
  '.CartItem',
  '.cart__item',
  '.cart-template__item',
  '.cart_item',
  '.cart-item .card',
  '.tt-shopcart-table tr',
  '.mini-cart__item',
  '.cart__card',
  '.cart__items__row',
  '.cart-list li',
  '.upcart-product-item',
  'tr.cart__row',
  '.rebuy-cart__flyout-item',
  '.mini-cart__line-ite',
  '.cart--item',
];

// Can be updated using window.GIFTBOX_CUSTOM_CART_REMOVE_BUTTON_SELECTORS.
export const selectorsCartRemoveButton = [
  'cart-remove-button',
  '.cart__product-information .cart__remove',
  '.cart-drawer__controls .cart-drawer__remove',
  '.cart__table-cell--name .cart__remove',
  '.grid__item .cart__remove',
  '.cart__table-cell--meta .cart__remove',
  '.cart-item__meta .cart-item__remove',
  '.cart-drawer__item-content .cart-drawer__item-delete',
  '.grid__item .cart__product-meta a',
  '.grid-item a.remove',
  '.line-item__quantity .line-item__quantity-remove',
  '.CartItem__Actions .CartItem__Remove',
  '.cart-template__remove',
  '.cart-item-quantity .remove',
  '.cart__remove a',
  '.cart__remove',
  'a.remove',
];

// Can be updated using window.GIFTBOX_CUSTOM_CART_REMOVE_LINK_SELECTORS.
export const selectorsCartRemoveLink = [
  'cart-remove-button a',
  '.cart-item__details a',
  '.cart__product-information .list-view-item__title a',
  '.cart__table-cell--name a',
  '.grid__item a.cart__product-title',
  '.cart__table-cell--meta a',
  '.cart__cell--image a',
  '.cart-item__meta a.cart-item__meta-title',
  '.cart-drawer__item-content a.cart-drawer__item-title',
  '.grid__item a.cart__product-name',
  '.grid__item a',
  '.grid-item a',
  '.line-item__meta a',
  '.CartItem__Title a',
  '.cart-template__details a',
  '.cart-item-title a',
  '.mini-cart__item a',
  'a.description__title',
  'a[href*="/products"]',
  'a[href*="variant="]',
];

// Can be updated using window.GIFTBOX_CUSTOM_CART_REMOVE_INPUTS_SELECTORS.
export const selectorsCartRemoveInputs = [
  'button:not(.button--tertiary,.cart-drawer__remove,.cart-drawer__item-delete)',
  'input',
  'span.js--qty-adjuster',
  'span.ajaxifyCart--qty-adjuster',
  '.ajaxcart__qty-adjust',
  '.QuantitySelector__Button',
  '.quick-cart__item-total',
  '.js-change-quantity',
  '.product-minus',
  '.product-plus',
  'span.plus-btn',
  'span.minus-btn',
  '.product-quantity-box',
  '.product-quantity',
  '.quantity-selector',
  '.quantity__plus',
  '.quantity__minus',
  '.js-qty__wrapper',
  '.quantity.buttoned-input',
  '.quantity-down',
  '.quantity-up',
  '.quantity__button',
  '.js-qty__num',
  '.js-qty__adjust',
];

// Can be updated using window.GIFTBOX_CUSTOM_SECTION_TO_UPDATE_SELECTORS.
export const selectorsSectionToUpdate = [
  '[id*="shopify-section"][id*="cart-items"]',
  '[id*="shopify-section"][id*="cart-template"]',
];

// Can be updated using window.GIFTBOX_CUSTOM_SECTIONS_WITH_SUBELEMENTS.
export const sectionsWithSubelements = ['cart-drawer'];

// Can be updated using window.GIFTBOX_CART_ITEMS_COUNT_SELECTORS.
export const selectorsCartLink = ['a[href="/cart"]'];

export const disabledQuantityElementOpacity = '0.25';

// Can be updated using window.GIFTBOX_SUPPORTED_THEMES_LIST.
export const supportedThemesList = [
  'Dawn',
  'Refresh',
  'Minimal',
  'Craft',
  'Sense',
  'Ride',
  'Studio',
  'Taste',
  'Colorblock',
  'Crave',
  'Simple',
  'Brooklyn',
];

// Can be updated using window.GIFTBOX_COLLECTION_GRID_WRAPPER_SELECTORS.
export const collectionGridWrapperSelectors = [
  '#ProductGridContainer',
  'ul#product-grid',
  'ul.product-grid',
  '.collection__main .product-list',
  '.collection-grid__wrapper',
  '.collection__products',
  '#CollectionAjaxContent',
  '.collection .product-list--collection',
  '.collection-grid',
  '.product-list-container',
  '.ProductListWrapper',
  '#CollectionGrid',
  '.collection--body--grid',
  '.product-grid',
  '.collection__results .product-list',
  'ul.productgrid--items',
];

// Can be updated using window.GIFTBOX_COLLECTION_HIDING_PRODUCT_WRAPPER_SELECTORS.
export const collectionProductWrapperSelectors = [
  'li.grid__item',
  'div.grid__item',
  '.product-card',
  '.js-grid div',
  '.product-item',
  '.grid-product',
  '.product-block',
  '.Grid__Cell',
  '.product-grid-item',
  '.product--root',
  '.product-block',
  'li.productgrid--item',
];

// Corresponds to window.Shopify.theme.theme_store_id
export const collectionProductWrapperRemovalThemeIds = [
  857, // Impulse
];

export const drawerSelectorListByPriority = [
  '#cart-notification',
  '#CartDrawer',
  '.cart-popup-wrapper',
  '#cart',
  '.mini-cart',
  '.cart',
  '.mini_cart',
  '.cart_container',
  '.cart-popup',
  '.drawer',
  '.cart_content',
  '.Drawer',
  '.cart-preview',
  '.cart-drawer-form',
  '.theme-ajax-cart',
  '.ajax-cart__form',
];
