import { Button, ButtonPair } from '../domain';
import { logInfo, logError } from '../utils/logging';
import { createDraftOrderEvent } from './createDraftOrderEvent';

const CHECKOUT_BUTTON_SELECTORS = [
  'button[name="checkout"]',
  'input[name="checkout"]',
  'form[action^="/checkout"] [type="submit"]',
  '[href*="checkout"]',
  'button[value="Checkout"]',
  'input[value="Checkout"]',
];

export function invariant(condition: boolean, message: string) {
  if (!condition) {
    throw new Error(message);
  }
}

export function isVisible(element: HTMLElement) {
  return !!(
    element.offsetWidth ||
    element.offsetHeight ||
    element.getClientRects().length
  );
}

function customIgnoreQuery(element) {
  if (!window.GiftBox.customIgnoreButtonSelector) return true;
  try {
    return !element.matches(window.GiftBox.customIgnoreButtonSelector);
  } catch (error) {
    logError('customIgnoreButtonSelector is not a valid selector', error);
  }
}

function getButtons(): Button[] {
  window.GiftBox.customButtonSelectors =
    window.GiftBox.customButtonSelectors ||
    window.GIFTBOX_CUSTOM_BUTTON_SELECTORS ||
    [];

  const selectors = CHECKOUT_BUTTON_SELECTORS.concat(
    window.GiftBox.customButtonSelectors,
  )
    .map((selector) => `${selector}:not(.giftbox-checkout-cloned)`)
    .join(',');

  return Array.from(document.querySelectorAll<Button>(selectors))
    .concat(
      Array.from(
        document.querySelectorAll<Button>(
          '[onclick*="checkout"]:not(.giftbox-checkout-cloned)',
        ),
      ).filter(
        (element) =>
          !!element &&
          !!element?.attributes &&
          (element.attributes as any)?.onclick.value.match(
            /location.*checkout/,
          ),
      ),
    )
    .filter(
      (element) =>
        !buttonPairs.find(
          (pair) =>
            pair.hiddenButton === element || pair.clonedButton === element,
        ),
    )
    .filter(
      (element) => !Array.from(element.classList).includes('partiallyButton'),
    )
    .filter(customIgnoreQuery)
    .filter(isVisible);
}

const buttonPairs: ButtonPair[] = [];

function hideButton(button: Button) {
  button.setAttribute(
    'style',
    'display:none!important;visibility:hidden;position:absolute;left:-10000px;',
  );
}

export function cleanupUnpairedButtons() {
  try {
    const clonedButton = document.querySelectorAll('.giftbox-checkout-cloned');
    clonedButton.forEach((button) => {
      const buttonPair = buttonPairs.find(
        (pair) => pair.clonedButton === button,
      );

      if (!buttonPair) {
        logInfo('Removing unpaired button', button);
        button.classList.remove('giftbox-checkout');
        button.classList.remove('giftbox-checkout-cloned');
      }
    });
  } catch (error) {
    logError(error);
  }
}

function pairButtons(buttons: Button[]) {
  buttons.forEach((button) => {
    button.classList.add('giftbox-checkout');

    const clonedButtonNode = button.cloneNode(true) as Button;
    clonedButtonNode.onclick = null;
    clonedButtonNode.classList.add('giftbox-checkout-cloned');
    button.insertAdjacentElement('afterend', clonedButtonNode);

    hideButton(button);
    watchButtonRemoval(button);
    watchButtonChanges(button);

    buttonPairs.push({
      clonedButton: clonedButtonNode,
      hiddenButton: button,
    });
  });
}

export function cloneButtons() {
  if (window.GIFTBOX_DISABLE_CHECKOUT_BTN_CLONING) {
    logInfo('disabled the cloning of the checkout button');
    return;
  }
  const buttons = getButtons();

  if (buttons) {
    pairButtons(buttons);
  }

  logInfo(`found ${buttons.length} buttons`);

  buttonPairs.forEach(({ clonedButton, hiddenButton }) => {
    clonedButton.addEventListener(
      'click',
      async (event) => {
        createDraftOrderEvent(event, hiddenButton);
      },
      { capture: true },
    );
  });
}

function getPairByHiddenButton(button: Button): ButtonPair | undefined {
  return buttonPairs.find((pair) => pair.hiddenButton === button);
}

function findInsertedButton(addedNodes: Node[], hiddenButton: Button) {
  return Array.from(addedNodes).find(
    (node) =>
      node.nodeName === hiddenButton.nodeName &&
      (node as HTMLInputElement).type === hiddenButton.type,
  );
}

function hideInsertedButton(button: Button, mutationsList: MutationRecord[]) {
  mutationsList.forEach((mutation) => {
    if (mutation.type === 'childList') {
      Array.from(mutation.removedNodes).forEach((node) => {
        if (node !== button) return;

        logInfo(
          `${
            (node as HTMLInputElement).id
          } is being removed. Updating reference.`,
        );
        const buttonPair = getPairByHiddenButton(button);

        if (!buttonPair) return;
        const insertedButton = findInsertedButton(
          mutationsList.flatMap((e) => Array.from(e.addedNodes)),
          buttonPair.hiddenButton,
        ) as Button;

        buttonPair.hiddenButton = insertedButton;
        hideButton(insertedButton);
      });
    }
  });
}

function watchButtonRemoval(button: Button) {
  try {
    const observer = new MutationObserver((mutationList) =>
      hideInsertedButton(button, mutationList),
    );

    if (!button?.parentElement) return;
    observer.observe(button.parentElement, { childList: true });
  } catch (error) {
    logError('Failed to setup button observer', error);
  }
}

function updateButtonAttributes(button: Button) {
  logInfo(`${button.id} is being modified. Updating content.`);

  const pairs = getPairByHiddenButton(button);
  if (!pairs) return;
  const { clonedButton, hiddenButton } = pairs;
  if (hiddenButton.style.display !== 'none') {
    hideButton(hiddenButton);
  }

  clonedButton.disabled = hiddenButton.disabled;
  clonedButton.className = hiddenButton.className;
}

function watchButtonChanges(button: Button) {
  try {
    const observer = new MutationObserver(() => updateButtonAttributes(button));
    observer.observe(button, {
      childList: true,
      attributes: true,
      subtree: true,
    });
  } catch (error) {
    logError('Failed to setup button observer', error);
  }
}

export function getHiddenButton(clonedButton: Button) {
  const buttonPair = buttonPairs.find(
    (pair) => pair.clonedButton === clonedButton,
  );
  invariant(
    !!buttonPair,
    `Hidden button couldn't be found for: ${clonedButton}`,
  );
  return buttonPair?.hiddenButton;
}
