export const PREVIEW_HASH_KEY = 'giftbox-preview';
export const PREVIEW_ERROR_KEY = 'giftbox-preview-error';
export const ERROR_INVALID_PREVIEW =
  'Invalid preview URL. Defaulting to active offers only.';

export function getPreviewHash() {
  const previewHash = window.sessionStorage.getItem(PREVIEW_HASH_KEY);
  if (previewHash) return previewHash;

  const hasError = window.sessionStorage.getItem(PREVIEW_ERROR_KEY);
  if (hasError) return undefined;

  const parsedUrl = new URL(window.location.href);
  const previewHashUrlParam = parsedUrl.searchParams.get('giftbox_preview');
  if (previewHashUrlParam) {
    window.sessionStorage.setItem(PREVIEW_HASH_KEY, previewHashUrlParam);
    return previewHashUrlParam;
  }

  return undefined;
}
