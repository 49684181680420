import { GhostProductHandlePairs } from '../domain';
import {
  collectionProductWrapperSelectors,
  collectionGridWrapperSelectors,
  collectionProductWrapperRemovalThemeIds,
} from './selectors';
import { updateCustomSelectors } from './helpers';
import { logInfo } from '../utils/logging';

const hideGhostProductsOnCollectionPages = (
  handlePairs: GhostProductHandlePairs,
) => {
  const gpHandlesSearchQuery = Object.keys(handlePairs).map(
    (handle) => `a[href*="${handle}"]`,
  );
  const allSelectorsCollectionGridWrapperSelector = updateCustomSelectors(
    collectionGridWrapperSelectors,
    'GIFTBOX_COLLECTION_GRID_WRAPPER_SELECTORS',
  );
  const allSelectorsCollectionProductWrappers = updateCustomSelectors(
    collectionProductWrapperSelectors,
    'GIFTBOX_COLLECTION_HIDING_PRODUCT_WRAPPER_SELECTORS',
  );

  const collectionGridWrappers = document.querySelectorAll(
    allSelectorsCollectionGridWrapperSelector,
  );

  const performHiding = (target: null | Node) => {
    let wrappers: null | HTMLElement[] | undefined;
    if (target === null) {
      // Selecting all wrappers for inital search
      wrappers = Array.from(collectionGridWrappers);
    } else {
      // Getting a wrapper where DOM mutation happened - stored as an array to unify the data structure
      wrappers = Array.from(collectionGridWrappers).filter(
        (wrapper: HTMLElement) => wrapper.contains(target),
      );
    }
    if (wrappers.length) {
      wrappers.forEach((wrapper) => {
        // Querying all grid items within a collection and filtering them based on visibility
        const collectionProductWrappers = wrapper.querySelectorAll(
          allSelectorsCollectionProductWrappers.join(','),
        );

        // Filters out hidden wrappers and wrappers that would other selectors miss match
        const visibleSingleCollectionProductWrappers = Array.from(
          collectionProductWrappers,
        ).filter(
          (wrapper: HTMLElement) =>
            (wrapper.offsetWidth || wrapper.offsetWidth) &&
            !wrapper.querySelector(allSelectorsCollectionProductWrappers),
        );

        visibleSingleCollectionProductWrappers.forEach((wrapper) => {
          // Querying the anchor tag containing GP handle in the href
          const query = gpHandlesSearchQuery.join(',');
          const anchor: HTMLAnchorElement | undefined =
            wrapper.querySelector(query);

          if (anchor) {
            logInfo(`Hiding product: ${anchor.href}`);

            if (
              window.GIFTBOX_REMOVE_COLLECTION_PRODUCT_WRAPPER ||
              collectionProductWrapperRemovalThemeIds.includes(
                window.Shopify.theme.theme_store_id,
              )
            ) {
              wrapper.remove();
              return;
            }
            // Hiding the grid item, the following approach is needed for adding !important
            wrapper.style.setProperty('display', 'none', 'important');
          }
        });
      });
    }
  };

  // Callback, that runs on DOM changes within the collection wrappers, to remove ghost products
  const hideCollectionCallback = (mutationList: MutationRecord[]) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        performHiding(mutation.target);
      }
    }
  };

  // Initial hiding on all found collection wrappers
  performHiding(null);
  const observer = new MutationObserver(hideCollectionCallback);
  collectionGridWrappers.forEach((gridWrapper) => {
    observer.observe(gridWrapper, {
      childList: true,
      subtree: true,
    });
  });
};

export default hideGhostProductsOnCollectionPages;
