import { debounce } from "tiny-throttle";
import { getPreviewHash } from "../core/preview";
import { logError } from "../utils/logging";
import {
  AppInfoResponse,
  Cart,
  CartUpdates,
  CollectionsInCart,
  OffersResponse,
  OfferStorage,
  StoredGiftLink,
} from "../domain";
import { GIFT_LINK } from "./constants";
import { isStorageAvailable } from "../common/utils/isStorageAvailable";
import { getDiscountCodeFromStorageOrFallbackToCookie } from "./discountUtils";

export const API_ERRORS = {
  PREVIEW_FORBIDDEN: "preview_forbidden",
};

export function stringifyUrlParams(
  obj: Record<string, number[] | string | string[]>,
) {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, String(v)));
    } else {
      params.append(key, String(value));
    }
  }
  return params.toString();
}

export function getUrlQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  let queryParams: Record<string, string[]> = {};
  for (let paramName of searchParams.keys()) {
    queryParams[paramName] = searchParams.getAll(paramName);
  }
  return queryParams;
}

function getStoredGiftLinks(): StoredGiftLink[] {
  let storedGiftLinksJSON: string | null = '';
  if (isStorageAvailable()) {
    storedGiftLinksJSON = localStorage.getItem(GIFT_LINK.LOCAL_STORAGE_NAME);
  }
  if (storedGiftLinksJSON) {
    try {
      return JSON.parse(storedGiftLinksJSON);
    } catch (error) {
      return [];
    }
  }
  return [];
}

export function setStoredGiftLinks(giftLinks: StoredGiftLink[]) {
  if (isStorageAvailable()) {
    localStorage.setItem(
      GIFT_LINK.LOCAL_STORAGE_NAME,
      JSON.stringify(giftLinks),
    );
  }
}

function isStoredGiftLinkValid(storedGiftLink: StoredGiftLink): boolean {
  const currentTimestamp = new Date();
  if (storedGiftLink?.lastTimeUsed) {
    const diffMS =
      currentTimestamp.getTime() -
      new Date(storedGiftLink.lastTimeUsed).getTime();
    const res = diffMS < GIFT_LINK.GIFT_LINK_EXPIRATION_MS;
    return res;
  }
  return false;
}

function filterStoredGiftLinks(
  storedGiftLinks: StoredGiftLink[],
  newGiftLinkValues: string[],
): StoredGiftLink[] {
  let validGiftLinks = storedGiftLinks.filter((storedGiftLink) => {
    const reusedGiftLink: boolean = newGiftLinkValues.some(
      (giftLink) => giftLink === storedGiftLink.giftLink,
    );
    const res = isStoredGiftLinkValid(storedGiftLink) && !reusedGiftLink;
    return res;
  });
  const currentTimestamp = new Date();
  for (const value of newGiftLinkValues) {
    const newGiftLink = {
      giftLink: value,
      lastTimeUsed: currentTimestamp,
    };
    validGiftLinks.push(newGiftLink);
  }

  return validGiftLinks;
}

export function getGiftLinkQueryParam() {
  const queryParams = getUrlQueryParams();

  let newGiftLinksValues: string[] = queryParams['gift_link'] || [];

  let giftLinks: StoredGiftLink[] = getStoredGiftLinks();
  giftLinks = filterStoredGiftLinks(giftLinks, newGiftLinksValues);

  setStoredGiftLinks(giftLinks);
  return giftLinks.map((giftLink) => giftLink.giftLink);
}

export function getApiRequestConfig(method = 'GET') {
  const conf = {};
  const previewHash = getPreviewHash();
  const headers = previewHash ? { 'x-giftbox-preview': previewHash } : {};
  if (method.toLowerCase() === 'post') {
    headers['Content-Type'] = 'application/json; charset=utf-8';
    conf['method'] = 'POST';
    conf['credentials'] = 'same-origin';
  }
  conf['headers'] = headers;
  return conf;
}

export async function parseJson(fetchPromise: Promise<Response> | Response) {
  const response = await fetchPromise;
  if (response.status >= 500) {
    throw Error(`Something unexpected happened. Status: ${response.status}`);
  }
  if (response.status >= 400) {
    throw Error(`Status: ${response.status}`);
  }
  return await response.json();
}

export function getCurrentLocale() {
  const path = window.location.pathname;
  const matches = /^\/([a-z]{2}(?:-[a-z]{2})?)(?:\/|$)/gi.exec(path);
  return matches && matches[1] ? `${matches[1]}` : '';
}

export async function getProduct(handle: string) {
  let shopLocale = getCurrentLocale();
  shopLocale = shopLocale ? `/${shopLocale}` : '';
  try {
    return await parseJson(
      fetch(`${shopLocale}/products/${handle}.js`, getApiRequestConfig()),
    );
  } catch (error) {
    logError(`Product ${handle} is unavailable ... trying fallback`);
    const hostname = process.env.REACT_APP_API_HOSTNAME;
    const url = `${hostname}api/product-fallback?handle=${handle}&shop=${window.Shopify.shop}`;
    let response = await fetch(url, getApiRequestConfig());

    if (response.status === 403) {
      response = await fetch(url, getApiRequestConfig());
    }
    return await parseJson(response);
  }
}

export function getCart(): Promise<Cart> {
  return parseJson(
    fetch(`/cart.js?_=${new Date().getTime()}`, {
      credentials: 'same-origin',
      cache: 'no-store',
    }),
  );
}

export async function updateCart(updates: CartUpdates) {
  let res = await fetch('/cart/update.js', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      updates,
    }),
  });
  return await res.json();
}

export async function getOffers(myshopifyDomain: string) {
  const hostname = process.env.REACT_APP_API_HOSTNAME;
  const previewHash = getPreviewHash();
  const giftLinks = getGiftLinkQueryParam();
  const queryParams = stringifyUrlParams({
    shop: myshopifyDomain,
    gift_links: giftLinks,
  });

  let url = `${hostname}api/offers?${queryParams}`;
  if (previewHash) {
    // send preview hash also via get param so that cloud flare cache distinguish between preview and standard mode
    url += `&giftbox-preview=${previewHash}`;
  }
  let response = (await fetch(url, getApiRequestConfig())) as OffersResponse &
    Response;

  if (response.status === 403) {
    return {
      offers: [],
      settings: {},
      error: API_ERRORS.PREVIEW_FORBIDDEN,
      remainingOffers: 0,
    } as OffersResponse;
  }

  response = await parseJson(response);
  if (response.offers) {
    response.offers.forEach((offer) => {
      offer.original_price = Number(offer.original_price);

      if (offer.threshold) {
        offer.threshold =
          Number(offer.threshold) * Number(window.Shopify.currency.rate);
      }
    });
    response.offers = response.offers.filter(
      (offer) =>
        (!offer.end_datetime || new Date(offer.end_datetime) > new Date()) &&
        (previewHash
          ? true
          : new Date(offer?.start_datetime ?? -1) <= new Date()),
    );
  }

  return response;
}

export const getAppInfo = async (myshopifyDomain: string) => {
  const hostname = process.env.REACT_APP_API_HOSTNAME;
  const previewHash = getPreviewHash();
  const queryParams = stringifyUrlParams({
    shop: myshopifyDomain,
  });
  let url = `${hostname}api/app-info?${queryParams}`;
  if (previewHash) {
    // send preview hash also via get param so that cloud flare cache distinguish between preview and standard mode
    url += `&giftbox-preview=${previewHash}`;
  }

  try {
    let response = (await fetch(
      url,
      getApiRequestConfig(),
    )) as AppInfoResponse & Response;
    response = await parseJson(response);
    return response;
  } catch (e) {
    logError(`App-info endpoint failed: ${e}`);
    return {
      config: null,
      gp_handle_pairs: null,
      injection_method: '',
      gp_hiding_in_collections_disabled: false,
    };
  }
};

export function createDraftOrder(
  myshopifyDomain: string,
  cart: Cart,
  offers: OfferStorage[],
  shippingAddress: string,
) {
  const hostname = process.env.REACT_APP_API_HOSTNAME;
  const data = {
    shop: myshopifyDomain,
    cart,
    offers,
    currency: window.Shopify.currency,
    shippingAddress,
    discount_code: getDiscountCodeFromStorageOrFallbackToCookie(),
  };
  return parseJson(
    fetch(`${hostname}api/create-draft-order`, {
      ...getApiRequestConfig('POST'),
      body: JSON.stringify(data),
    }),
  );
}

function sendAddedOffersToBackend(
  addedOffers: OfferStorage[],
  cartToken: Cart['token'],
): Promise<Response> | undefined {
  const addedOffersWithoutFreeShippingOffers = addedOffers.filter(
    (offer) => offer.productVariantId,
  );
  if (addedOffersWithoutFreeShippingOffers.length === 0) {
    return;
  }

  const cartTokenCleaned = cartToken.replace(/\?key=.*/, "")

  const data = {
    shop: window.Shopify.shop,
    cartToken: cartTokenCleaned,
    currency: window.Shopify.currency,
    addedOffers: addedOffersWithoutFreeShippingOffers,
  };

  const hostname = process.env.REACT_APP_API_HOSTNAME;
  return fetch(`${hostname}api/added-offers`, {
    ...getApiRequestConfig('POST'),
    body: JSON.stringify(data),
  });
}

export const debouncedSendAddedOffersToBackend = debounce(
  sendAddedOffersToBackend,
  1000,
);

/*
 * If an error occurs, we return null to prevent further processing with incorrect information.
 * This also handles scenarios where the user navigates away and cancels the call, causing an error.
 */
export async function getCollectionsInCart(
  collectionIds: number[],
  productIds: number[],
): Promise<CollectionsInCart | null> {
  if (collectionIds.length === 0 || productIds.length === 0) {
    return [];
  }

  const hostname = process.env.REACT_APP_API_HOSTNAME;
  const obj = {
    shop: window.Shopify.shop,
    collection_ids: collectionIds,
    product_ids: productIds,
  };

  const query = stringifyUrlParams(obj);

  try {
    return await parseJson(
      fetch(
        `${hostname}api/collections-in-cart?${query}`,
        getApiRequestConfig(),
      ),
    );
  } catch (error) {
    logError(error);
    return null;
  }
}
