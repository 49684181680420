export function getCookie(name: string) {
  return parseCookies()[name] || '';
}

export function deleteCookie(name: string) {
  document.cookie = name + '=; Path=/; Max-Age=-99999999;';
}

function parseCookies() {
  var cookieData = (typeof document.cookie === 'string'
    ? document.cookie
    : ''
  ).trim();

  return (cookieData ? cookieData.split(';') : []).reduce(
    (cookies, cookieString) => {
      var cookiePair = cookieString.split('=');
      cookies[cookiePair[0].trim()] =
        cookiePair.length > 1 ? cookiePair[1].trim() : '';
      return cookies;
    },
    {},
  );
}
