import {
  debouncedSendAddedOffersToBackend,
  getCart,
  getOffers,
} from "../../../api";
import { calculateGiftQuantity, isRejected } from "../helpers";
import {
  determineEligibleOffers,
  getAddedOffers,
  getCartCollections,
  persistAddedOffersToStorage,
  saveAddedOffer,
} from "../";
import {
  getCartUpdates,
  getUniqueAddedOffers,
  isGiftInOffer,
  updateCartLineItems,
} from "../../../dom/ghost-products-mode";
import {
  Cart,
  CartItem,
  CollectionsInCart,
  Offer,
  OfferStorage,
} from "../../../domain";
import { logInfo } from "../../../utils/logging";

async function unifyAddedOffers() {
  const uniqueAddedOffers = await getUniqueAddedOffers();
  persistAddedOffersToStorage(uniqueAddedOffers);
  window.dispatchEvent(new Event("offersUpdated"));

  return uniqueAddedOffers;
}

async function getEligibleOffersProducts(
  cart: Cart,
  uniqueAddedOffers: OfferStorage[],
  collectionsInCart: CollectionsInCart
) {
  const { offers } = await getOffers(window.Shopify.shop);
  const eligibleOffers = determineEligibleOffers(
    offers,
    cart,
    uniqueAddedOffers,
    collectionsInCart,
    true
  );

  return eligibleOffers.filter(
    (eligibleOffer) =>
      !isRejected(eligibleOffer.shopify_product_id) &&
      (eligibleOffer.type === "MultipleProductOffer" ||
        eligibleOffer.type === "ProductOffer" ||
        eligibleOffer.type === "ServiceOffer")
  );
}

export function getGhostProductsInCart(cart: Cart) {
  return cart.items.filter(
    (cartItem) => cartItem.product_type === "giftbox_ghost_product"
  );
}

export function getExcessiveCartGiftsUpdates(
  cartFreeGifts: CartItem[],
  cart: Cart,
  eligibleOffers: Offer[],
  collectionsInCart: CollectionsInCart
) {
  let excessiveCartGifts = {};
  cartFreeGifts.forEach((freeGift) => {
    if (!(freeGift.variant_id in excessiveCartGifts)) {
      excessiveCartGifts[freeGift.variant_id] = 0;
    }
    excessiveCartGifts[freeGift.variant_id] += freeGift.quantity;

    eligibleOffers.forEach((eligibleOffer) => {
      if (isGiftInOffer(freeGift, eligibleOffer)) {
        const giftQuantity = calculateGiftQuantity(
          cart,
          eligibleOffer,
          collectionsInCart
        );
        excessiveCartGifts[freeGift.variant_id] -= giftQuantity;
      }
    });
  });

  let freeGiftsInCartUpdates = {};
  cartFreeGifts.forEach((freeGiftInCart) => {
    freeGiftsInCartUpdates[freeGiftInCart.variant_id] = freeGiftInCart.quantity;
  });

  let cartUpdates = {};
  Object.keys(excessiveCartGifts).forEach((variantId) => {
    const excessiveQuantity = excessiveCartGifts[variantId];
    const currentQuantity =
      variantId in freeGiftsInCartUpdates
        ? freeGiftsInCartUpdates[variantId]
        : excessiveQuantity;
    if (excessiveQuantity > 0) {
      cartUpdates[variantId] = Math.max(currentQuantity - excessiveQuantity, 0);
    }
  });

  return cartUpdates;
}

export function getMissingCartGifts(
  freeGiftsInCart: CartItem[],
  eligibleOffers: Offer[]
) {
  if (!freeGiftsInCart.length) return eligibleOffers;

  return eligibleOffers.filter((eligibleOffer) => {
    return !freeGiftsInCart.find((freeGift) =>
      isGiftInOffer(freeGift, eligibleOffer)
    );
  });
}

export async function validateCart() {
  // Unify added offers in the local storage.
  const uniqueAddedOffers = await unifyAddedOffers();
  // Fetch current cart content.
  const cart = await getCart();

  // is used for gift calculation in the case of 'collection_added'
  const collectionsInCart = await getCartCollections(
    uniqueAddedOffers.map(
      (storageOffer) =>
        ({
          threshold_mode: storageOffer.thresholdMode,
          target_shopify_collection_id: storageOffer.targetShopifyCollection_id,
        } as Offer)
    ),
    cart
  );
  if (!collectionsInCart) {
    logInfo('No collections in cart, skipping cart validation.');
    return;
  }

  // Get eligible offers of the cart.
  const eligibleOffersProducts = await getEligibleOffersProducts(
    cart,
    uniqueAddedOffers,
    collectionsInCart
  );
  // Get all Ghost products in the cart.
  const freeGiftsInCart = getGhostProductsInCart(cart);

  // Determine GPs to remove.
  const removeFromCartUpdates = getExcessiveCartGiftsUpdates(
    freeGiftsInCart,
    cart,
    eligibleOffersProducts,
    collectionsInCart
  );

  // Determine missing offers.
  const missingOffers = getMissingCartGifts(
    freeGiftsInCart,
    eligibleOffersProducts
  );

  // Prepare cart updates (add/remove GPs)
  // and get completely missing eligible offers.
  const { cartUpdates, completelyMissingOffers } = await getCartUpdates(
    uniqueAddedOffers,
    removeFromCartUpdates,
    missingOffers
  );

  // Update cart line items if any update available.
  if (Object.keys(cartUpdates).length > 0) {
    try {
      await updateCartLineItems(cartUpdates);
    } catch (error) {
      console.error(`Cart Validation - Failed to update cart: ${error}`);
    }
  }

  // Add eligible offers missing in cart and also in local storage.
  completelyMissingOffers.forEach(async (eligibleOffer) => {
    saveAddedOffer(cart, eligibleOffer, collectionsInCart);
  });

  const addedOffers = getAddedOffers();
  debouncedSendAddedOffersToBackend(addedOffers, cart.token);
}
