import { getAddedOffers } from '../../core/products';
import { CartItem, Offer, OfferStorage } from '../../domain';

export async function getUniqueAddedOffers() {
  const addedOffers = await getAddedOffers();
  const uniqueOffers: OfferStorage[] = [];

  addedOffers.forEach((addedOffer) => {
    const index = uniqueOffers.findIndex(
      (uniqueOffer) => uniqueOffer?.offerId === addedOffer.offerId,
    );
    if (index <= -1) {
      uniqueOffers.push(addedOffer);
    }
  });

  return uniqueOffers;
}

export function isGiftInOffer(gift: CartItem, offer: Offer) {
  if (offer?.products) {
    return !!offer.products.find((offerProduct) => {
      if (offerProduct.ghost_product) {
        return (
          offerProduct.ghost_product.shopify_product_id === gift.product_id
        );
      }

      return offerProduct.shopify_product_id === gift.product_id;
    });
  }

  return (
    offer?.ghost_product?.shopify_product_id === gift.product_id ||
    offer.shopify_product_id === gift.product_id
  );
}

export async function getCartUpdates(
  uniqueAddedOffers: OfferStorage[],
  removeFromCartUpdates: Record<string, number>,
  missingOffers: Offer[],
) {
  const addToCartUpdates = {};
  const completelyMissingOffers: Offer[] = [];

  for (const eligibleOffer of missingOffers) {
    const alreadyAddedOffer = uniqueAddedOffers.find(
      (addedOffer) => addedOffer.offerId == eligibleOffer.id,
    );
    if (alreadyAddedOffer) {
      if (!alreadyAddedOffer.rejected && alreadyAddedOffer.shopifyVariantId) {
        addToCartUpdates[
          alreadyAddedOffer.shopifyVariantId
        ] = alreadyAddedOffer.giftQuantity
      }
    } else {
      completelyMissingOffers.push(eligibleOffer);
    }
  }

  return {
    cartUpdates: { ...removeFromCartUpdates, ...addToCartUpdates },
    completelyMissingOffers: completelyMissingOffers,
  };
}
