import { updateRejectInOffer } from '../../core/products';
import { updateCustomSelectors } from '../helpers';
import { detectGhostProductItem } from './cart';
import {
  disabledQuantityElementOpacity,
  selectorsCartItems,
  selectorsCartRemoveButton,
  selectorsCartRemoveInputs,
} from '../selectors';
import { OfferStorage, ShopifyVariantId } from '../../domain';
import { logInfo } from '../../utils/logging';

export function disableButtonsInCart(addedOffers: OfferStorage[]) {
  const allSelectorsCartItems = updateCustomSelectors(
    selectorsCartItems,
    'GIFTBOX_CUSTOM_CART_ITEMS_SELECTORS',
    window.GIFTBOX_MATCH_CART_ITEMS_DIRECTLY,
  );

  const cartItems = document.querySelectorAll(allSelectorsCartItems.join(','));

  cartItems.forEach((cartItem) => {
    const shopifyVariantId = detectGhostProductItem(cartItem, addedOffers);
    if (shopifyVariantId) {
      disableCartItemControls(cartItem, shopifyVariantId);
    }
  });
}

export function disableCartItemControls(
  cartItem: Element,
  shopifyVariantId: ShopifyVariantId,
) {
  const allSelectorsCartRemoveInputs = updateCustomSelectors(
    selectorsCartRemoveInputs,
    'GIFTBOX_CUSTOM_CART_REMOVE_INPUTS_SELECTORS',
  );
  cartItem
    .querySelectorAll(allSelectorsCartRemoveInputs.join(','))
    .forEach((element) => {
      // Do not set element.disabled - cart form will not work.
      if (element.hasAttribute('readonly')) {
        element.readOnly = true;
      }
      element.style.opacity = disabledQuantityElementOpacity;
      element.style.pointerEvents = 'none';
    });
  const allSelectorsCartRemoveButton = updateCustomSelectors(
    selectorsCartRemoveButton,
    'GIFTBOX_CUSTOM_CART_REMOVE_BUTTON_SELECTORS',
  );
  const removeButtons = cartItem.querySelectorAll(
    allSelectorsCartRemoveButton.join(','),
  );

  const rejectCallback = async () => {
    await updateRejectInOffer(shopifyVariantId, true);
    logInfo(
      window.GIFTBOX_SHOULD_REJECT_OFFER_MOUSEDOWN_LISTENER
        ? 'Using touchstart/mousedown listener for offer rejection'
        : 'Using click listener for offer rejection',
    );
  };
  removeButtons.forEach((removeButton) => {
    // There might be an issue with some buttons that are anchor tags, using mousedown / touchstart can help
    if (window.GIFTBOX_SHOULD_REJECT_OFFER_MOUSEDOWN_LISTENER) {
      removeButton.addEventListener('mousedown', rejectCallback);
      removeButton.addEventListener('touchstart', rejectCallback);
    } else {
      removeButton.addEventListener('click', rejectCallback);
    }
  });
}
