import { debounce } from 'tiny-throttle';
import { cloneButtons, isVisible } from './buttons';
import { logInfo, logError } from '../utils/logging';
import { Callback, EnhancedCb, Selector, Settings } from '../domain';
import { drawerSelectorListByPriority } from './selectors';

function cloneButtonsMutation(mutationsList: MutationRecord[]) {
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      mutation.addedNodes.forEach((node: HTMLFormElement['value']) => {
        if (
          node.querySelector &&
          node.querySelector(
            'form[action*="/cart"],form[action*="/checkout"],[type=submit]',
          )
        ) {
          cloneButtons();
        }

        const checkoutButton = document.querySelector(
          '[href$="checkout"]:not(.giftbox-checkout)',
        ) as HTMLElement;
        if (checkoutButton && isVisible(checkoutButton)) {
          cloneButtons();
        }
      });
    }
  }
}

function enhancedCb(): EnhancedCb {
  const _cb = (mutationsList: MutationRecord[]) => {
    for (const cb of _cb.enhancers) {
      cb(mutationsList);
    }
  };
  _cb.enhancers = [];
  _cb.inject = function (e) {
    this.enhancers.push(e);
  };
  return _cb as EnhancedCb;
}

function observeSelectors(
  selectors: Selector[],
  cb: MutationCallback,
  maxObserverCount = 2,
) {
  const selectorsObservers: MutationObserver[] = [];
  let counter = 0;
  for (const selector of selectors) {
    if (counter === maxObserverCount) {
      break;
    }
    const name = typeof selector === 'string' ? selector : selector.name;
    const value = typeof selector === 'string' ? selector : selector.value;
    const element = document.querySelector(value);
    if (element) {
      const observer = hookMutationObserverToElement(element, value, name, cb);
      if (observer) {
        selectorsObservers.push(observer);
        counter++;
      }
    }
  }
  return selectorsObservers;
}

function disableObservers(observers: MutationObserver[]) {
  for (const observer of observers) {
    if (observer) {
      observer.disconnect();
    }
  }
}

function observeForms(cb: Callback<unknown>) {
  const forms = document.querySelectorAll("form[action^='/checkout']");
  const formObservers: MutationObserver[] = [];
  forms.forEach((form) => {
    const formObserver = new MutationObserver(cb);
    formObserver.observe(form, {
      attributes: true,
    });
    formObservers.push(formObserver);
  });
  return formObservers;
}

function hookMutationObserverToElement(
  element: Element,
  selector: Selector,
  selectorName = '',
  cb,
) {
  if (element) {
    logInfo(`Found ${selectorName ? selectorName : selector}: ${selector}`);
    const observer = new MutationObserver(cb);
    observer.observe(element, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['class'],
    });
    return observer;
  }
}

const debouncedCloneButtons = debounce(cloneButtons, 500);

class CartWatcher {
  rootCallback: EnhancedCb;
  drawerCallback: EnhancedCb;
  selectorObservers: MutationObserver[];
  settings: Settings;

  constructor() {
    this.updateObservers = this.updateObservers.bind(this);
    this.rootCallback = enhancedCb();
    this.drawerCallback = enhancedCb();
    this.rootCallback.inject(cloneButtonsMutation);
    this.drawerCallback.inject(debouncedCloneButtons);
    this.selectorObservers = [];
    this.settings = {};
  }

  setupDefaultObservers() {
    try {
      const targetNode = document.body;

      const observer = new MutationObserver(this.rootCallback);
      observer.observe(targetNode, { childList: true, subtree: true });

      observeForms(this.rootCallback);

      this.selectorObservers = observeSelectors(
        drawerSelectorListByPriority,
        this.drawerCallback,
      );
    } catch (error) {
      logError('Failed to setup drawer cart observer', error);
    }
  }

  updateObserversFromSettings(settings: Settings) {
    this.settings = settings;
    if (
      !settings.ajax_cart_watcher_selector ||
      !document.querySelector(settings.ajax_cart_watcher_selector)
    ) {
      return;
    }

    try {
      const backOfficeSelector = settings?.ajax_cart_watcher_selector ?? null;

      let selectors = drawerSelectorListByPriority;
      if (backOfficeSelector) {
        disableObservers(this.selectorObservers);
        selectors = [backOfficeSelector, ...drawerSelectorListByPriority];
      }

      observeSelectors(selectors, this.drawerCallback);
    } catch (error) {
      logError('Failed to setup drawer cart observer', error);
    }
  }

  updateObservers() {
    if (this.settings) {
      this.updateObserversFromSettings(this.settings);
    }
  }
}

const cartWatcher = new CartWatcher();
export default cartWatcher;

// Needed here because this file is used in admin too
window.GiftBox = window.GiftBox || {};
window.GiftBox.cloneButtons =
  window.GiftBox.cloneButtons || debouncedCloneButtons;
window.GiftBox.updateObservers =
  window.GiftBox.updateObservers || debounce(cartWatcher.updateObservers, 500);
window.GiftBox.drawerCallback =
  window.GiftBox.drawerCallback || cartWatcher.drawerCallback;
