import { Cart } from '../domain';
import isFunction from './isFunction';
// If updated, check that the property is a function due to API changes of the carts
const updateDrawerCarts = (updatedCart: Cart) => {
  // Custom update cart callback
  if (isFunction(window.GIFTBOX_CUSTOM_UPDATE_CART)) {
    return window.GIFTBOX_CUSTOM_UPDATE_CART(updatedCart);
  }

  // Drawer Carts API call - all conditions should be tested due to non-active apps
  if (isFunction(window.wetheme?.updateCartDrawer)) {
    window.wetheme.updateCartDrawer(updatedCart);
  }

  if (isFunction(window.refreshCart)) {
    window.refreshCart(updatedCart);
  }

  if (isFunction(window.ajaxCart?.load)) {
    window.ajaxCart.load();
  }

  if (isFunction(window.monster_setCartItems) && updatedCart?.items?.length) {
    window.monster_setCartItems(updatedCart.items);
  }

  if (isFunction(window.updateCartDrawerUI)) {
    window.updateCartDrawerUI();
  }

  if (
    isFunction(window.cart?.updateCart) &&
    isFunction(window.cart?.toggleCartDropdown)
  ) {
    window.cart.updateCart();
    window.cart.toggleCartDropdown();
  }

  if (isFunction(window.cart?.getCart)) {
    window.cart.getCart();
  }

  if (isFunction(window.SLIDECART_UPDATE)) {
    window.SLIDECART_UPDATE();
  }

  if (isFunction(window.HS_SLIDE_CART_UPDATE)) {
    window.HS_SLIDE_CART_UPDATE();
  }

  if (isFunction(window.store?.getCart)) {
    window.store.getCart();
  }

  if (isFunction(window.monster_setCartItems)) {
    window.monster_setCartItems(updatedCart.items);
  }
};

export default updateDrawerCarts;
