import {
  Cart,
  CartItem,
  CollectionsInCart,
  Offer,
  Product,
  Variant,
} from "../../domain";
import { logInfo } from "../../utils/logging";
import {
  isCoveredCollectionsInCart,
  isCoveredCollectionsInCartWithSpend,
  isCoveredProductsInCart,
  isCoveredThresholdInCart,
} from "./covered";
import { getCartCollectionProductsSpend } from "./products";
import { getAddedOffers } from "./storage";

export function getProductOriginalPrice(variant: Variant) {
  return (
    (variant.compare_at_price > variant.price
      ? variant.compare_at_price
      : variant.price) / 100
  );
}

export function getCartPrice(cart: Cart) {
  if (window.GIFTBOX_FORCE_USE_TOTAL_PRICE) {
    return cart.total_price / 100;
  }
  let spending = cart.discountSpendings
    ? cart.discountSpendings.total_spending
    : cart.total_price;
  return spending / 100;
}

export function isProductUnavailableOrLoading(product: Product) {
  return !product || !product.variants;
}

export function remainingOfferAmount(
  offer: Offer,
  cart: Cart,
  collectionsInCart: CollectionsInCart
): number {
  const { threshold } = offer;

  if ("spend_in_collection" === offer.threshold_mode) {
    const spendInCollection = getCartCollectionProductsSpend(
      offer,
      cart,
      collectionsInCart
    );
    return (threshold ?? -1) - spendInCollection;
  }

  return (threshold ?? -1) - getCartPrice(cart);
}

export function offerOverThreshold(
  offer: Offer,
  cart: Cart,
  collectionsInCart: CollectionsInCart
) {
  return (
    isCoveredThresholdInCart(offer, cart) ||
    isCoveredProductsInCart(offer, cart) ||
    isCoveredCollectionsInCart(offer, cart, collectionsInCart) ||
    isCoveredCollectionsInCartWithSpend(offer, cart, collectionsInCart)
  );
}

export const isRejected = (productId: number | null) => {
  const offers = getAddedOffers();

  const items = offers.filter((item) => {
    return item?.productId === productId;
  });

  if (items.length === 0) {
    return false;
  } else {
    return items[0].rejected;
  }
};

/**
 * @param cart: active cart
 * @param offer: the active gift offer
 * @returns number: quantity of the gift based on cart and offer
 */
export const calculateGiftQuantity = (
  cart: Cart,
  offer: Offer,
  collectionIdToProductIdsMapper
) => {
  /***************************************************
   custom gift quantity or 1
   **************************************************/
  if (offer.gift_quantity !== 0) {
    return offer.gift_quantity || 1;
  }

  /***************************************************
   match the quantity of the product added to the cart
   **************************************************/

  // because the user can add multiple variants of the same product that satisfies the gift
  // we need to get the sum of all those products. We are only interested if the
  // variant is targeted.
  if (offer.threshold_mode === "product_added") {
    const productSum = cart.items.reduce((acc, item) => {
      if (
        offer.target_shopify_product_id === item.product_id &&
        (offer.target_all_variants_selected ||
          offer.target_shopify_product_variants.includes(item.variant_id))
      ) {
        acc += item.quantity;
      }
      return acc;
    }, 0);
    return productSum || 1;
  }

  // in this scenario we need to get all the products in the cart that are
  // part of the collection that satisfies the gift offer and sum up their quantity
  if (offer.threshold_mode === "collection_added") {
    logInfo(
      "calculateGiftQuantity for collection_added where collectionIdToProductIdsMapper: ",
      collectionIdToProductIdsMapper
    );
    const productIds =
      collectionIdToProductIdsMapper[offer.target_shopify_collection_id] || [];
    const collectionQuantitySum = productIds.reduce(
      (partialSum: number, productId: number) => {
        const products = cart.items.filter(
          (item) => item.product_id === productId
        );
        const productsQuantities = products.reduce(
          (quantitySum: number, cartItem: CartItem) =>
            quantitySum + (cartItem?.quantity || 0),
          0
        );
        return (partialSum += productsQuantities);
      },
      0
    );

    return collectionQuantitySum || 1;
  }

  // all the other scenarios
  return (
    cart.items.find(
      (item) => offer.target_shopify_product_id === item.product_id
    )?.quantity || 1
  );
};
