import { Offer, Product } from "../../domain";

export function getAvailableOfferTargetVariantsSet(
  offer: Offer,
  targetProduct?: Product
): Set<number> {
  if (!targetProduct?.available || !targetProduct?.variants) {
    return new Set([]);
  }

  // Get all available variants from the target product
  const availableTargetProductVariants = targetProduct.variants.filter(
    (variant) => variant.available
  );

  // Extract IDs of available variants
  const availableVariantIDs = new Set(
    availableTargetProductVariants.map((variant) => variant.id)
  );

  // we can return all available target product variants, no need to filter
  if (offer.target_all_variants_selected) {
    return availableVariantIDs;
  }

  // Filter the offer's target variants to only include those that are available
  const availableOfferTargetShopifyProductVariants =
    offer.target_shopify_product_variants.filter((targetVariantID) =>
      availableVariantIDs.has(targetVariantID)
    );
  return new Set(availableOfferTargetShopifyProductVariants);
}
