import { logInfo, logError } from './utils/logging';
import { cloneButtons, cleanupUnpairedButtons } from './dom';
import cartWatcher from './dom/cart-watcher';
import { deleteCookie } from './utils/cookies';
import { LOCALSTORAGE_KEY } from './utils/keys';
import { validateCart } from './core/products/validateCart';
import { getSettings } from './core/products/storage/settings';
import { render, ContainerNode } from 'preact';
import sendErrorToSentry from './utils/sentry';
import { getAppInfo } from './api';
import {
  createEventListenerRedirect,
  handleRedirectToParent,
} from './utils/ghostProductRedirect';
import hideGhostProductsOnCollectionPages from './dom/collectionHiding';

window.GiftBox = window.GiftBox || {};

async function initialize() {
  try {
    const appInfo = await getAppInfo(window.Shopify.shop);
    // Config needs to run before any Giftbox related property/method is accessed
    if (appInfo.config && !window.GIFTBOX_CONFIG_EXECUTED) {
      const config = appInfo.config?.trim();
      if (config) {
        try {
          window.GIFTBOX_CONFIG_EXECUTED = true;
          window.eval(config);
        } catch (e) {
          logError('Config file contains an error');
        }
      }
    }

    if (window.DISABLE_GIFT_BOX) {
      logInfo('disabled by DISABLE_GIFT_BOX');
      return;
    }

    logInfo('loaded 🎉');
    if (window.Shopify.checkout || window.Shopify.Checkout) {
      logInfo(`order status page`);
      window.sessionStorage.removeItem('giftbox-discount');
      window.sessionStorage.removeItem(LOCALSTORAGE_KEY);
      deleteCookie('discount_code');
    } else {
      if (document.getElementById('giftbox-root')) {
        logInfo('root is already present.');
        return;
      }

      if (!appInfo.gp_hiding_in_collections_disabled) {
        if (appInfo.gp_handle_pairs) {
          hideGhostProductsOnCollectionPages(appInfo.gp_handle_pairs);
        }
      }

      if (
        appInfo.gp_handle_pairs &&
        appInfo.injection_method !== 'app_extension'
      ) {
        if (!window.GIFTBOX_GP_PREVENT_FALLBACK_REDIRECT) {
          // Click event listener to change GP's redirect to parent or homepage
          handleRedirectToParent(appInfo.gp_handle_pairs);
        }

        if (!window.GIFTBOX_GP_PREVENT_EVENT_LISTENER_REDIRECT) {
          // Redirect to GP's parent or homepage on load
          createEventListenerRedirect(appInfo.gp_handle_pairs);
        }
      }

      cleanupUnpairedButtons();
      cloneButtons();
      cartWatcher.setupDefaultObservers();

      document.body.insertAdjacentHTML(
        'beforeend',
        '<div id="giftbox-root"></div>',
      );

      const settings = getSettings();
      if (settings.use_ghost_products) await validateCart();

      import(/* webpackPreload: true */ './app').then(({ default: App }) =>
        render(
          <App />,
          document.getElementById('giftbox-root') as ContainerNode,
        ),
      );
    }
  } catch (error) {
    logError(error);
    sendErrorToSentry(error);
  }
}

if (
  document.readyState === 'complete' ||
  document.readyState === 'interactive'
) {
  initialize();
} else {
  document.addEventListener('DOMContentLoaded', initialize);
}
