import { getCart, getCurrentLocale, createDraftOrder } from '../api';
import { getFilteredAddedOffers, getSettings } from '../core/products';
import { Button } from '../domain';
import { logInfo, logError } from '../utils/logging';
import { invariant } from './buttons';

function getAttributes() {
  const attributesElements = document.querySelectorAll('[name^="attributes"]');
  const attributes = {};

  attributesElements.forEach((element) => {
    const attributesElement = element as HTMLInputElement;
    if (!attributesElement.name) return;

    const accessor = attributesElement?.name.match(/\[(.*)\]/)?.[1];
    const isCheckbox = attributesElement.type === 'checkbox';
    let { value } = attributesElement;

    if (isCheckbox) {
      value = attributesElement.checked ? value : '';
    }

    if (value !== null && typeof accessor !== 'undefined') {
      attributes[accessor] = attributesElement.value;
    }
  });

  return attributes;
}

function clickHiddenButton(target: HTMLInputElement, hiddenButton: Button) {
  // Button needs to be re-enabled before redirects due to Safari cache issues
  target.disabled = true;

  logInfo('No offers added using normal checkout...');
  if (window.$ && !(hiddenButton instanceof HTMLAnchorElement)) {
    logInfo('jQuery found. Clicking using jQuery.');
    try {
      window.$(hiddenButton).trigger('click');
    } catch (error) {
      logError('jQuery click failed. Falling back to .click()', error);
      hiddenButton.click();
    }
  } else {
    hiddenButton.click();
  }
  target.disabled = false;
}

function updateCartAttributes(cart) {
  cart.attributes = {
    ...cart.attributes,
    ...getAttributes(),
  };

  const noteElement = document.querySelector<HTMLInputElement>('[name=note]');
  if (noteElement && noteElement.value) {
    cart.note = noteElement.value;
  }

  invariant(cart.items.length > 0, `Cart is empty.`);
}

export async function createDraftOrderEvent(
  event: MouseEvent,
  hiddenButton: Button,
) {
  event.preventDefault();
  event.stopImmediatePropagation();

  try {
    if (window.GIFTBOX_CAN_CHECKOUT && !window.GIFTBOX_CAN_CHECKOUT()) {
      logInfo('GIFTBOX_CAN_CHECKOUT prevented checkout');
      return;
    }
  } catch (error) {
    logError('GIFTBOX_CAN_CHECKOUT check failed', error);
  }

  try {
    const offers = getFilteredAddedOffers();

    if (!offers || offers.length === 0) {
      clickHiddenButton(event.target as HTMLInputElement, hiddenButton);
      return;
    }

    const cart = await getCart();

    updateCartAttributes(cart);

    const settings = getSettings();
    const shopLocale = window.GIFTBOX_LOCALE
      ? window.GIFTBOX_LOCALE
      : getCurrentLocale();

    if (!settings.use_ghost_products) {
      const draftOrder = await createDraftOrder(
        window.Shopify.shop,
        cart,
        offers,
        window.giftBoxShippingAddress,
      );
      logInfo(draftOrder);
      window.location.href =
        draftOrder.invoice_url + (shopLocale ? `?locale=${shopLocale}` : '');
    } else {
      hiddenButton.click();
    }
  } catch (error) {
    logError('Draft order creation failed', error);
    hiddenButton.click();
  }
}
