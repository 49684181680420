import { getProduct } from "../../api";
import isMobile from "../../common/utils/isMobile";
import { AnimationContextProps } from "../../contexts/AnimationContext";
import { isCartPage } from "../../dom";
import {
  Cart,
  CollectionsInCart,
  Offer,
  OfferStorage,
  Product,
  Settings,
  Variant,
} from "../../domain";
import { logError } from "../../utils/logging";
import Tracker from "../../utils/tracking";
import {
  calculateGiftQuantity,
  isProductUnavailableOrLoading,
  offerOverThreshold,
} from "./helpers";
import {
  ADDED_OFFERS_KEY,
  ADDED_OFFERS_PREVIOUS_ITERATION_KEY,
  FIRST_VISIT_KEY,
  getAddedOffers,
  getAddedOffersPreviousIteration,
  getSettings,
  OPEN_MODAL_AFTER_RECEIVING_GIFT_KEY,
  resetModifiedOffers,
} from "./storage";
import { getAvailableOfferTargetVariantsSet } from "./variantUtils";

export const getGhostProductsVariants = (offers: { offers: Offer[] }) => {
  const ghostProductsVariants: number[] = [];

  offers?.offers?.forEach((offer) => {
    if (offer.type === "MultipleProductOffer") {
      offer?.products?.forEach((product) => {
        product?.ghost_product?.variants?.forEach((variant) => {
          ghostProductsVariants.push(variant.shopify_variant_id);
        });
      });
    } else {
      offer?.ghost_product?.variants?.forEach((variant) => {
        ghostProductsVariants.push(variant.shopify_variant_id);
      });
    }
  });

  return ghostProductsVariants;
};

export function getCartCollectionProductsSpend(
  offer: Offer,
  cart: Cart,
  collectionsInCart: CollectionsInCart
) {
  const collectionProductsInCart =
    collectionsInCart[offer.target_shopify_collection_id] || [];

  let filteredCartItems = cart.items.filter((item) =>
    collectionProductsInCart.includes(item.product_id)
  );

  if (cart.discountSpendings) {
    filteredCartItems = cart.discountSpendings.spendings_per_line_item.filter(
      (spendingItem) =>
        filteredCartItems.find(
          (filteredItem) => filteredItem.id === spendingItem.cart_line_item_id
        )
    );
  }

  let cartCollectionProductsSpend = 0;

  for (const item of filteredCartItems) {
    let price = item.final_line_price;
    if (cart.discountSpendings) {
      price -= item.discount_amount;
    }
    cartCollectionProductsSpend += price / 100;
  }
  console.assert(
    cartCollectionProductsSpend >= 0,
    `spend in collection cant be negative, value: ${cartCollectionProductsSpend}`
  );
  return cartCollectionProductsSpend;
}

// this is important because we retrigger elibible offers again
// even though those are already added in the cart since the quantity can change
export const isSameOfferWithDifferentQuantity = (
  storageOffer: OfferStorage,
  apiOffer: Offer,
  cart: Cart,
  collectionsInCart: CollectionsInCart
) => {
  const apiOfferQuantity = calculateGiftQuantity(
    cart,
    apiOffer,
    collectionsInCart
  );
  const storageOfferQuantity = storageOffer.giftQuantity;
  const hasChanged = storageOfferQuantity !== apiOfferQuantity;

  return (
    storageOffer.offerId === apiOffer.id &&
    hasChanged
  );
};

export function determineEligibleOffers(
  offers: Offer[],
  cart: Cart,
  storageOffers: OfferStorage[],
  collectionsInCart: CollectionsInCart,
  shouldIgnoreAddedOffers: boolean = false // ignore the offers that are saved in the storage
) {
  const settings = getSettings();
  if (!settings.gift_only_highest_gift) {
    // get only the api offers when the api offer and storage offer have different quantity (but same id)
    // with different product variant or a completely different id
    return offers.filter((offer) => {
      const isEveryOfferElibible = storageOffers.every((storageOffer) => {
        const quantityChanged =
          isSameOfferWithDifferentQuantity(
            storageOffer,
            offer,
            cart,
            collectionsInCart
          );
        return (
          shouldIgnoreAddedOffers ||
          storageOffer.offerId !== offer.id ||
          (quantityChanged)
        );
      });
      const isOfferOverThreshold = offerOverThreshold(
        offer,
        cart,
        collectionsInCart
      );
      return isEveryOfferElibible && isOfferOverThreshold;
    });
  }

  const offersOverThreshold = offers.filter((offer) =>
    offerOverThreshold(offer, cart, collectionsInCart)
  );

  if (offersOverThreshold.length === 0) return [];

  const mostValuableCurrentOffer =
    getMostValuableOffer<Offer>(offersOverThreshold);
  const mostValuableAddedOffer =
    getMostValuableOffer<OfferStorage>(storageOffers);
  const newEligibleOffer =
    mostValuableCurrentOffer.id !== mostValuableAddedOffer.offerId ||
    isSameOfferWithDifferentQuantity(
      mostValuableAddedOffer,
      mostValuableCurrentOffer,
      cart,
      collectionsInCart
    )
      ? [mostValuableCurrentOffer]
      : [];

  if (shouldIgnoreAddedOffers) {
    return [mostValuableCurrentOffer];
  }
  return newEligibleOffer;
}

export function checkForAllSoldoutVariants(
  product: Product | undefined,
  offer: Offer
) {
  const offerProduct = offer?.products?.find(
    (p) => p.shopify_product_handle === product?.handle
  );
  if (offerProduct) {
    return (product?.variants ?? [])
      .filter((v) => offerProduct.shopify_product_variants.includes(v.id))
      .every((v) => !v.available);
  }
  return false;
}

export function availableProductVariants(
  product: Product | undefined,
  offer: Offer
): Variant[] | undefined {
  if (!product || isProductUnavailableOrLoading(product)) return;
  if (
    !offer.shopify_product_variants ||
    offer.shopify_product_variants.length === 0
  ) {
    return product.variants;
  }

  return (product.variants ?? []).filter((v) =>
    offer.shopify_product_variants.includes(v.id)
  );
}

export function getFilteredAddedOffers() {
  const settings = getSettings();
  const offers = getAddedOffers();

  if (settings.gift_only_highest_gift) {
    let mostValuableOffer: OfferStorage | undefined =
      getMostValuableOffer(offers);

    // Handling cornercase when offers have same threshold
    const freeShippingOffer = offers.find(
      (o) => o.type === "FreeShippingOffer"
    );
    mostValuableOffer =
      freeShippingOffer?.threshold === mostValuableOffer.threshold
        ? freeShippingOffer
        : mostValuableOffer;

    if (mostValuableOffer?.type === "FreeShippingOffer") {
      return [];
    }
  }

  return offers.filter((offer) => offer.productVariantId);
}

function lastOrDefault(
  array: any[],
  cond: (args: Offer) => boolean,
  defaultValue = null
) {
  if (!array.length) return defaultValue;
  if (!cond) return array[array.length - 1];

  for (var i = array.length - 1; i >= 0; --i) {
    if (cond(array[i])) return array[i];
  }

  return defaultValue;
}

export function visibleOffers(offers: Offer[], settings: Settings) {
  if (!settings.gift_only_highest_gift) {
    return offers;
  }
  let sortedOffers = offers.sort(
    (a, b) =>
      new Date(a?.start_datetime ?? -1).valueOf() -
      new Date(b?.start_datetime ?? -1).valueOf()
  );
  sortedOffers = sortedOffers.sort(
    (a, b) => (a?.threshold ?? -1) - (b?.threshold ?? -1)
  );
  const lastAddedOfferIndex = sortedOffers.indexOf(
    lastOrDefault(sortedOffers, (offer) => offer?.added ?? false)
  );
  if (lastAddedOfferIndex > 0) {
    return sortedOffers.slice(lastAddedOfferIndex);
  }
  return offers;
}

export function shouldOpen(settings: Settings, justAddedOffers: number[]) {
  const { open_when_in_cart, open_after_receiving_gift } = settings;

  const addedOffersIDs = getAddedOffers().map(
    (offerStorage) => offerStorage.offerId
  );
  const addedOffersPreviousIterationIDs = getAddedOffersPreviousIteration().map(
    (offerStorage) => offerStorage.offerId
  );

  // persist current added offers storage value to the previous iteration, so that we will have
  // it when we come here again.
  const currentAddedOffersStorageValue = localStorage.getItem(ADDED_OFFERS_KEY);
  localStorage.setItem(
    ADDED_OFFERS_PREVIOUS_ITERATION_KEY,
    currentAddedOffersStorageValue ? currentAddedOffersStorageValue : ""
  );

  const isNewOfferIdExists = addedOffersIDs.find(
    (missingOfferId) =>
      !addedOffersPreviousIterationIDs.find((o) => o === missingOfferId)
  );
  if (
    settings.gift_only_highest_gift
      ? isNewOfferIdExists
      : addedOffersIDs.length > addedOffersPreviousIterationIDs.length
  ) {
    localStorage.setItem(OPEN_MODAL_AFTER_RECEIVING_GIFT_KEY, "true");
  }

  if (
    open_after_receiving_gift &&
    justAddedOffers.length > 0 &&
    localStorage.getItem(OPEN_MODAL_AFTER_RECEIVING_GIFT_KEY) === "true"
  ) {
    localStorage.setItem(OPEN_MODAL_AFTER_RECEIVING_GIFT_KEY, "");
    return true;
  }
  if (open_when_in_cart && isCartPage() && !shouldOpen.did_open) {
    shouldOpen.did_open = true;

    return true;
  }

  return false;
}

shouldOpen.did_open = false;

export function openOnFirstVisit(
  settings: Settings,
  setOpen: (arg: boolean) => void,
  tracker: Tracker,
  offers: Offer[],
  cart: Cart,
  animationContext: AnimationContextProps
) {
  if (
    settings.open_on_first_visit &&
    !sessionStorage.getItem(FIRST_VISIT_KEY)
  ) {
    sessionStorage.setItem(FIRST_VISIT_KEY, "true");
    setTimeout(() => {
      if (!isMobile()) {
        animationContext.triggerWithAnimation?.({
          name: "first_visit_auto_open_desktop",
          delayMsBefore: 400,
          delayMsAfter: 500,
          callback: () => {
            setOpen(true);
            tracker.trackLauncherClickOpen(offers, cart);
          },
        });
      } else {
        setOpen(true);
        tracker.trackLauncherClickOpen(offers, cart);
      }
    }, (settings.open_on_first_visit_delay ?? 0) * 1000);
  }
}

export function calculateThresholdFromCollection(
  offer: Offer,
  cart: Cart,
  collectionsInCart: CollectionsInCart
) {
  const collectionProductsInCart =
    collectionsInCart[offer.target_shopify_collection_id] || [];

  const filteredCartItems = cart.items.filter((item) =>
    collectionProductsInCart.includes(item.product_id)
  );
  let cartCollectionProductsTotalPrice = 0;
  for (const item of filteredCartItems) {
    cartCollectionProductsTotalPrice +=
      (offer.target_min_quantity * item.price) / 100;
  }

  return cartCollectionProductsTotalPrice;
}

export const getMostValuableOffer = <T>(offers: T[]) =>
  offers.reduce(
    (max, current) =>
      // @ts-ignore
      Number(max.threshold) > Number(current.threshold) ? max : current,
    0
  );

export function verifyAddedVariantExistsInOffer(
  offer: Offer,
  product: Product,
  addedOffer: OfferStorage
) {
  switch (offer.type) {
    case "ProductOffer":
      return (
        offer.shopify_product_handle === product.handle &&
        product.variants?.find(
          (variant) => variant.id === addedOffer.productVariantId
        )
      );
    case "MultipleProductOffer":
      return offer?.products?.find(
        (offerProduct) =>
          offerProduct.shopify_product_handle === product.handle &&
          product.variants?.find(
            (variant) => variant.id === addedOffer.productVariantId
          )
      );
    case "ServiceOffer":
    case "FreeShippingOffer":
      return true;
    default:
      throw new Error("Unknown type");
  }
}

export function productOfferValid(
  offer: Offer,
  products: Product[],
  targetProducts: Product[]
) {
  if (offer.type === "ProductOffer") {
    const product = products.find(
      (product) =>
        product &&
        offer.shopify_product_handle === product.handle &&
        product.available
    );
    const targetProduct = targetProducts.find(
      (product) =>
        product &&
        offer.target_shopify_product_handle === product.handle &&
        product.available
    );
    if (
      product &&
      (offer.shopify_product_variants.length === 0 ||
        product.variants?.some(
          (v) => v.available && offer.shopify_product_variants.includes(v.id)
        )) &&
      (!offer.target_shopify_product_id ||
        getAvailableOfferTargetVariantsSet(offer, targetProduct).size > 0)
    ) {
      return product;
    }
  }
}

export function multipleProductOfferValid(
  offer: Offer,
  products: Product[],
  targetProducts: Product[]
) {
  let targetProduct: Product | undefined = targetProducts?.find(
    (product) =>
      product &&
      offer.target_shopify_product_handle === product.handle &&
      product.available
  );
  return (
    offer.type === "MultipleProductOffer" &&
    (offer?.products ?? []).filter((offerProduct) =>
      products.find(
        (product) =>
          product &&
          offerProduct.shopify_product_handle === product.handle &&
          product.available &&
          !checkForAllSoldoutVariants(product, offer)
      )
    ).length > 0 &&
    (!offer.target_shopify_product_id ||
      getAvailableOfferTargetVariantsSet(offer, targetProduct).size > 0)
  );
}

export function removeOffersWithUnavailableProductsOrUnavailableTargetProducts(
  offers: Offer[],
  products: Product[],
  targetProducts: Product[]
) {
  return offers.filter(
    (offer) =>
      ["FreeShippingOffer", "ServiceOffer"].includes(offer?.type ?? "") ||
      productOfferValid(offer, products, targetProducts) ||
      multipleProductOfferValid(offer, products, targetProducts)
  );
}

export async function loadProduct({
  products,
  shopify_product_handle: handle,
  setProduct,
}) {
  if (products?.[handle]) {
    return products[handle];
  }

  setProduct(handle, {
    loading: true,
  });

  try {
    const product = await getProduct(handle);
    product.loaded = true;
    product.loading = false;

    setProduct(handle, product);
    return product;
  } catch (error) {
    logError("Failed to load product, setting as not available.", error);
    console.warn(`GiftBox: Product ${handle} is not published.`);
    setProduct(handle, {
      loaded: true,
      loading: false,
      available: false,
    });
  }
}

async function preloadTargetProducts(
  offers: Offer[],
  setTargetProduct: (handle: unknown, data: Product) => void
) {
  const offersWithTargetProductHandle = offers.filter(
    (offer) =>
      (offer.type === "ProductOffer" ||
        offer.type === "MultipleProductOffer" ||
        offer.type === "ServiceOffer") &&
      offer.target_shopify_product_handle
  );

  return await Promise.all(
    offersWithTargetProductHandle.map((offer) =>
      loadProduct({
        shopify_product_handle: offer.target_shopify_product_handle,
        products: [],
        setProduct: setTargetProduct,
      })
    )
  );
}

async function preloadProducts(
  offers: Offer[],
  setProduct: (handle: unknown, data: Product) => void
) {
  const productOffers = offers.filter(
    (offer) => offer.type === "ProductOffer" && offer.shopify_product_handle
  );
  const multipleProductOffers = offers.filter(
    (offer) => offer.type === "MultipleProductOffer"
  );

  return await Promise.all([
    ...productOffers.map((offer) =>
      loadProduct({
        shopify_product_handle: offer.shopify_product_handle,
        products: offer.products,
        setProduct,
      })
    ),
    ...multipleProductOffers.flatMap((offer) =>
      (offer?.products ?? []).map((offerProduct) =>
        loadProduct({
          shopify_product_handle: offerProduct.shopify_product_handle,
          products: offer.products,
          setProduct,
        })
      )
    ),
  ]);
}

export async function preloadProductsAndFilterOffers(
  offers: Offer[],
  setProduct: (handle: unknown, data: Product) => void,
  setTargetProduct: (handle: unknown, data: Product) => void
) {
  const [targetProducts, products] = await Promise.all([
    preloadTargetProducts(offers, setTargetProduct),
    preloadProducts(offers, setProduct),
  ]);

  resetModifiedOffers(offers, products);

  const filteredOffers =
    removeOffersWithUnavailableProductsOrUnavailableTargetProducts(
      offers,
      products,
      targetProducts
    );

  return {
    offers: filteredOffers,
    products,
    targetProducts,
  };
}

export const setIconVisibility = (visibility: "hidden" | "visible"): void => {
  const root = document.getElementById("giftbox-root");
  if (!root) return;

  // Hide / Show modal - it's rendered out of the iframe
  let customStyle = document.getElementById("giftbox-custom-modal-style");
  if (!customStyle) {
    customStyle = document.createElement("style");
    customStyle.id = "giftbox-custom-modal-style";
    document.head.appendChild(customStyle);
  }

  const hiddenModal = `.react-modal-sheet-container {display: none !important;}`;
  const displayedModal = `.react-modal-sheet-container {display: flex !important;}`;

  customStyle.textContent =
    visibility === "hidden" ? hiddenModal : displayedModal;
  root.style.display = visibility === "hidden" ? "none" : "block";
};
