import {
  debouncedSendAddedOffersToBackend,
  getCart,
  getCollectionsInCart,
  getOffers,
} from "../../api";
import { updateCartLineItems } from "../../dom/ghost-products-mode";
import {
  Cart,
  CartUpdates,
  CollectionsInCart,
  GhostProduct,
  Offer,
  OfferStorage,
  Product,
  Settings,
  ShopifyVariantId,
  Variant,
} from "../../domain";
import isFunction from "../../utils/isFunction";
import { logError, logInfo } from "../../utils/logging";
import throttle from "../../utils/throttle";
import {
  availableProductVariants,
  calculateThresholdFromCollection,
  determineEligibleOffers,
  getGhostProductsVariants,
} from "./products";
import {
  getAddedOffers,
  getSettings,
  persistAddedOffersToStorage,
  persistJustAddedOffersToStorage,
  saveAddedOffer,
  updateCachedData,
} from "./storage";
import { getDiscountSpendings } from "../../api/discountUtils";

export type AddEligibleOffersToCartArgs = {
  offers: Offer[];
  products: Product[];
  offersCallback: (cart: Cart, collectionsInCart: CollectionsInCart) => void;
  settings: Settings;
  discountCode: string;
};

// this is used only for Ghost Products since in Draft Orders we dont show the items in the cart
export const addToCartIfIsNotThere = async (offers: OfferStorage[]) => {
  const updates: CartUpdates = {};
  const acceptedOffers = await getOffers(window.Shopify.shop);
  const settings = getSettings();
  const ghostProductsVariants = getGhostProductsVariants(acceptedOffers);

  ghostProductsVariants.forEach((variantid) => {
    updates[variantid] = 0;
  });

  offers.forEach((offer) => {
    if (settings?.gift_only_highest_gift) {
      acceptedOffers?.offers?.forEach((acceptedOffer) => {
        if (acceptedOffer.id === offer.offerId) {
          offer["start_datetime"] = acceptedOffer.start_datetime;
        }
      });
    }

    if (offer?.shopifyVariantId) {
      updates[offer.shopifyVariantId] = 0;
    }
  });

  let highestGift: Partial<OfferStorage> = {
    shopifyVariantId: undefined,
    threshold: 0,
  };

  offers.forEach((offer) => {
    if (!offer.rejected && offer.shopifyVariantId) {
      if (
        (highestGift?.threshold ?? -1) < (offer?.threshold ?? -1) ||
        highestGift.threshold === offer.threshold
      ) {
        highestGift = offer;
      }

      if (!settings?.gift_only_highest_gift) {
        updates[offer.shopifyVariantId] += offer.giftQuantity || 1;
      }
    }
  });

  if (settings?.gift_only_highest_gift && highestGift.shopifyVariantId) {
    updates[highestGift.shopifyVariantId] += highestGift.giftQuantity || 1;
  }

  await updateCartLineItems(updates);
};

export const deleteFromCart = async (ids: number[]) => {
  let updates = {};

  ids.forEach((id) => {
    if (id) {
      updates[id] = 0;
    }
  });

  try {
    await updateCartLineItems(updates);
  } catch (error) {
    console.log(`error in update cart: ${error}`);
  }
};

export async function updateRejectInOffer(
  shopifyVariantId: ShopifyVariantId,
  rejected: boolean
) {
  const addedOffers = getAddedOffers();
  const cart = await getCart();

  const offerList = addedOffers.map((addedOffer) => {
    if (addedOffer?.shopifyVariantId === shopifyVariantId) {
      addedOffer.rejected = rejected;
    }
    return addedOffer;
  });

  persistAddedOffersToStorage(offerList);
  window.dispatchEvent(new Event("offersUpdated"));
  return debouncedSendAddedOffersToBackend(addedOffers, cart.token);
}

export async function getCartCollections(offers: Offer[], cart: Cart) {
  const collection_ids = offers
    .filter((o) =>
      ["collection_added", "spend_in_collection"].includes(o.threshold_mode)
    )
    .map((o) => o.target_shopify_collection_id);
  const product_ids = cart.items.map((i) => i.product_id);
  return await getCollectionsInCart(collection_ids, product_ids);
}

type DebouncedAddEligibleOffersToCartFunction = (
  args: AddEligibleOffersToCartArgs
) => () => void;
export const debouncedAddEligibleOffersToCart: DebouncedAddEligibleOffersToCartFunction =
  throttle(addEligibleOffersToCart, 1000);

/**
 * Uses perstisted relevant added offer to fill the API offer with the already selected
 * productId, productVariant and productHandle. Its necessary upon changing navigation
 * to not loose the already selected user variants.
 * @param offer - The offer object to process.
 * @param addedOffers - Array of added offers to find relevant offer details.
 * @param products - Array of products to find the corresponding product.
 */
function fillOfferWithStorageAddedOfferInformation(
  offer: Offer,
  addedOffers: OfferStorage[],
  products: Product[]
) {
  if (offer.type === "ProductOffer" || offer.type === "MultipleProductOffer") {
    const relevantAddedOffer = addedOffers.find(
      (addedOffer) => addedOffer.offerId === offer.id
    );

    if (relevantAddedOffer && !relevantAddedOffer.rejected) {
      if (!offer.shopify_product_id) {
        offer.shopify_product_id = relevantAddedOffer.productId;
      }
      if (!offer.shopify_product_handle) {
        offer.shopify_product_handle = relevantAddedOffer.productHandle;
      }
    }
    if (!offer.shopify_product_variant_id) {
      const product = products.find(
        (product) =>
          product.id === offer.shopify_product_id ||
          product.handle === offer.shopify_product_handle
      );
      const availableProductVariantIds = availableProductVariants(
        product,
        offer
      )
        ?.filter((variant) => variant.available)
        .map((variant) => variant.id);

      if (
        relevantAddedOffer &&
        !relevantAddedOffer.rejected &&
        relevantAddedOffer.productVariantId &&
        availableProductVariantIds?.includes(
          relevantAddedOffer.productVariantId
        )
      ) {
        offer.shopify_product_variant_id = relevantAddedOffer.productVariantId;
      } else if (
        availableProductVariantIds &&
        availableProductVariantIds.length > 0
      ) {
        offer.shopify_product_variant_id = availableProductVariantIds[0];
      }
    }
  }
}

export async function addEligibleOffersToCart({
  offers,
  products,
  offersCallback,
  settings,
  discountCode,
}: AddEligibleOffersToCartArgs) {
  if (!discountCode && isFunction(window.GIFTBOX_GET_CUSTOM_DISCOUNT_CODE)) {
    discountCode = window.GIFTBOX_GET_CUSTOM_DISCOUNT_CODE();
  }
  logInfo("addEligibleOffersToCart", {
    offers,
    products,
    offersCallback,
    settings,
    discountCode,
  });
  let cart = await getCart();

  const collectionsInCart = await getCartCollections(offers, cart);
  if (!collectionsInCart) {
    logInfo(
      "addEligibleOffersToCart:No collections in cart, skipping further processing."
    );
    return;
  }
  if (
    discountCode &&
    settings.discount_adjust_spending &&
    settings.discounts_enabled
  ) {
    logInfo("addEligibleOffersToCart:before:discount_adjust_spending", cart);
    try {
      if (cart.item_count > 0) {
        const { total_spending, spendings_per_line_item } =
          await getDiscountSpendings(discountCode, cart);
        cart = {
          ...cart,
          discountSpendings: { total_spending, spendings_per_line_item },
        };
      }
      logInfo("addEligibleOffersToCart:after:discount_adjust_spending", cart);
    } catch (error) {
      logError("addEligibleOffersToCart:discounted cart error", error);
    }
  }

  await updateCachedData(cart, offers, collectionsInCart);

  const addedOffers = getAddedOffers();

  const eligibleOffers = determineEligibleOffers(
    offers,
    cart,
    addedOffers,
    collectionsInCart
  );

  for (const offer of eligibleOffers) {
    if (offer.threshold_mode === "product_added") {
      const foundItem = cart.items.find(
        (item) => item.product_id === offer.target_shopify_product_id
      );
      if (foundItem) {
        offer.spend = foundItem.price / 100;
      }
    }
    if (
      ["collection_added", "spend_in_collection"].includes(offer.threshold_mode)
    ) {
      offer.spend = calculateThresholdFromCollection(
        offer,
        cart,
        collectionsInCart
      );
    }
    if (
      offer.type === "ProductOffer" ||
      offer.type === "MultipleProductOffer"
    ) {
      fillOfferWithStorageAddedOfferInformation(offer, addedOffers, products);
    }

    await saveAddedOffer(cart, offer, collectionsInCart);
  }

  // justAddedOffers excluding offers alreagy present in addedOffers (with e.g. just gift quantity change)
  const justAddedOffers = eligibleOffers.filter((offer) =>
    addedOffers.every((addedOffer) => addedOffer.offerId != offer.id)
  );
  if (justAddedOffers.length) {
    // this change could be potentially related with https://digismoothie.atlassian.net/browse/APPS-7350
    persistJustAddedOffersToStorage(justAddedOffers.map((offer) => offer.id));
  }

  offersCallback(cart, collectionsInCart);
}

export function getOfferGhostProducts(offer: Offer) {
  let ghostProducts: Array<GhostProduct> = [];
  if (offer.ghost_product) {
    ghostProducts.push(offer.ghost_product);
  }
  if (offer.products) {
    offer.products.forEach((product) => {
      if (product.ghost_product) {
        ghostProducts.push(product.ghost_product);
      }
    });
  }

  return ghostProducts;
}

/**
 * Note: offer?.ghost_product is available only in Product/Service/Free shipping Offer
 *       offer?.products[] is available for ProduceChoice offer. Ghost product is part of each product in such case
 */
export async function updateOffer(
  offer: Offer,
  variant: Variant | undefined,
  productHandle: string = ""
) {
  let addedOffers = getAddedOffers();
  let isDirty = false;
  let cartToken; // This could be simplified. But we need to verify that server is getting addedOffers with current cart token only.
  let variantToAddId = variant && variant.id;
  const settings = getSettings();
  const cart = await getCart();

  for (const addedOffer of addedOffers) {
    if (addedOffer.offerId === offer.id) {
      // Basic dirty check to prevent saving unchanged data
      isDirty = isDirty || addedOffer.productVariantId !== variantToAddId;

      // Eligible gift changed/removed -> update the cart quantity.
      if (
        settings.use_ghost_products &&
        addedOffer.productVariantId !== variantToAddId &&
        addedOffer.shopifyVariantId
      ) {
        const cartLineItem = cart.items.find(
          (lineItem) => lineItem.variant_id === addedOffer.shopifyVariantId
        );
        const cartLineItemQuantity = cartLineItem?.quantity || 0;
        if (cartLineItemQuantity > 0) {
          const newQuantity = Math.max(
            cartLineItemQuantity - (addedOffer?.giftQuantity || 0),
            0
          );
          let cartUpdates = {};
          cartUpdates[addedOffer.shopifyVariantId] = newQuantity;
          await updateCartLineItems(cartUpdates);
          addedOffer.shopifyVariantId = undefined;
        }
      }

      cartToken = addedOffer.cartToken;
      addedOffer.productVariantId = variantToAddId;

      if (variant && offer.threshold_mode === "product_added") {
        addedOffer.threshold = variant.price / 100;
      }

      const currentProductHandle =
        productHandle || (variantToAddId && addedOffer.productHandle);
      // If no added variant we need to reset it to empty string
      addedOffer.productHandle = currentProductHandle || "";

      if (offer.type === "MultipleProductOffer") {
        const product = (offer?.products ?? []).find(
          (product) =>
            product.shopify_product_handle ===
            (productHandle || currentProductHandle)
        );
        if (product) {
          if (settings?.use_ghost_products) {
            const filteredGhost = (
              product?.ghost_product?.variants ?? []
            ).filter(
              (ghost_item) =>
                ghost_item.parent_shopify_variant_id === variant?.id
            );
            if (filteredGhost?.length > 0) {
              let shopifyVariantId = filteredGhost.pop()?.shopify_variant_id;
              addedOffer.shopifyVariantId = shopifyVariantId;
            }
          }
          addedOffer.productId = product?.shopify_product_id;
          addedOffer.productHandle = product?.shopify_product_handle;
        }
      } else {
        if (settings.use_ghost_products && offer?.ghost_product) {
          const filteredGhost = (offer?.ghost_product.variants ?? [])
            .filter(
              (ghost_item) =>
                ghost_item.parent_shopify_variant_id === variantToAddId
            )
            .filter((ghost_item) => typeof ghost_item !== "undefined");
          if (filteredGhost?.length > 0) {
            let shopifyVariantId = filteredGhost.pop()!.shopify_variant_id;
            addedOffer.shopifyVariantId = shopifyVariantId;
          }
        }
      }
    }
  }

  if (!isDirty) {
    return;
  }

  persistAddedOffersToStorage(addedOffers);
  window.dispatchEvent(new Event("offersUpdated"));

  debouncedSendAddedOffersToBackend(addedOffers, cartToken);

  if (settings.use_ghost_products) {
    await addToCartIfIsNotThere(addedOffers);
  }
}
