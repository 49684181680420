import { debouncedSendAddedOffersToBackend } from "../../../api";
import { isStorageAvailable } from "../../../common/utils/isStorageAvailable";
import { disableButtonsInCart } from "../../../dom/ghost-products-mode";
import {
  Cart,
  CollectionsInCart,
  Offer,
  OfferStorage,
  Product,
} from "../../../domain";
import { calculateGiftQuantity, offerOverThreshold } from "../helpers";
import {
  addToCartIfIsNotThere,
  deleteFromCart,
  getOfferGhostProducts,
} from "../cartManipulation";
import { verifyAddedVariantExistsInOffer } from "../products";
import {
  ADDED_OFFERS_KEY,
  ADDED_OFFERS_PREVIOUS_ITERATION_KEY,
  JUST_ADDED_OFFERS_KEY,
} from "./keys";
import { getSettings } from "./settings";
import { logInfo } from "../../../utils/logging";

export function getAddedOffers(): OfferStorage[] {
  return getAddedOffersFromStorage(ADDED_OFFERS_KEY);
}

export function persistAddedOffersToStorage(offers: OfferStorage[]) {
  localStorage.setItem(ADDED_OFFERS_KEY, JSON.stringify(offers));
}

export function getAddedOffersPreviousIteration(): OfferStorage[] {
  return getAddedOffersFromStorage(ADDED_OFFERS_PREVIOUS_ITERATION_KEY);
}

function getAddedOffersFromStorage(storageItemKey: string) {
  if (isStorageAvailable()) {
    const addedOffersKey: string | null = localStorage.getItem(storageItemKey);
    if (addedOffersKey) {
      try {
        return JSON.parse(addedOffersKey);
      } catch (error) {
        return [];
      }
    }
  }
  return [];
}

export function getJustAddedEligibleOffers(): number[] {
  const data = sessionStorage.getItem(JUST_ADDED_OFFERS_KEY);
  if (data) {
    try {
      sessionStorage.removeItem(JUST_ADDED_OFFERS_KEY);
      return JSON.parse(data);
    } catch (error) {
      return [];
    }
  }
  return [];
}

/*
 * We return the offer.shopify_product_id or try to locate it by using the shopify_product_variant_id
 * This may be needed when navigating back to the store after checkout was triggered.
 */
export function getProductIdFromOffer(offer: Offer): number | null {
  if (offer.shopify_product_id) {
    return offer.shopify_product_id;
  }
  if (
    offer.shopify_product_variant_id &&
    offer.products &&
    offer.products.length > 0
  ) {
    return (
      offer.products.find((product) =>
        product.shopify_product_variants.includes(
          offer.shopify_product_variant_id!
        )
      )?.shopify_product_id ?? null
    );
  }
  return null;
}

export async function saveAddedOffer(
  cart: Cart,
  offer: Offer,
  collectionsInCart: CollectionsInCart
) {
  const cartToken = cart.token;
  const settings = getSettings();
  let shopifyVariantId: number | null = null;

  // Assign the shopifyVariantId (it would be a good idea to extract this into another function)
  // Supports both ProductOffer and MultipleProductOffer
  if (settings.use_ghost_products) {
    const offerGhostProducts = getOfferGhostProducts(offer);
    offerGhostProducts.forEach((ghostProduct) => {
      const variants = ghostProduct.variants ?? [];
      variants.forEach((variant) => {
        if (
          variant.parent_shopify_variant_id === offer.shopify_product_variant_id
        ) {
          shopifyVariantId = variant?.shopify_variant_id ?? null;
        }
      });
    });
  }

  const addedOffers = getAddedOffers(); // from localstorage

  /**
   * Note: newAddedOffer should be extracted into a different type such as OfferStorage because it has a different
   * structure from Offer type. This can potentially fix bugs in the future because right now this is considering the same as Offer type
   */

  const newOfferGiftQuantity = calculateGiftQuantity(
    cart,
    offer,
    collectionsInCart
  );
  logInfo(`${offer.type} has gift quantity: ${newOfferGiftQuantity}`);
  const newAddedOffer: OfferStorage = {
    offerId: offer.id,
    giftQuantity: newOfferGiftQuantity,
    productId: getProductIdFromOffer(offer),
    productVariantId: offer.shopify_product_variant_id,
    shopifyVariantId: shopifyVariantId,
    productHandle: offer.shopify_product_handle || "",
    threshold: offer.spend || offer.threshold,
    thresholdMode: offer.threshold_mode,
    targetShopifyCollection_id: offer.target_shopify_collection_id,
    type: offer.type,
    rejected: false,
    cartToken: cartToken,
  };

  const otherAddedOffers = addedOffers.filter(
    (addedOffer) => addedOffer.offerId !== newAddedOffer.offerId
  );

  // when an offer has variants it is already added and when we change the variant
  // we keep the existing offer active.
  const newAddedOfferWithVariants = addedOffers.find(
    (addedOffer) => addedOffer.offerId === newAddedOffer.offerId
  );
  if (newAddedOfferWithVariants) {
    newAddedOfferWithVariants.giftQuantity = newOfferGiftQuantity;
  }

  const updatedAddedOffers = settings.gift_only_highest_gift
    ? [newAddedOfferWithVariants ? newAddedOfferWithVariants : newAddedOffer]
    : [...otherAddedOffers, newAddedOffer];

  debouncedSendAddedOffersToBackend(updatedAddedOffers, cartToken);

  if (settings.use_ghost_products) {
    await addToCartIfIsNotThere(updatedAddedOffers);
  }

  localStorage.setItem(ADDED_OFFERS_KEY, JSON.stringify(updatedAddedOffers));
  window.dispatchEvent(new Event("offersUpdated"));
}

/*
 * Persist just added eligible offers IDs to the session storage
 */
export function persistJustAddedOffersToStorage(offerIds: number[]) {
  sessionStorage.setItem(JUST_ADDED_OFFERS_KEY, JSON.stringify(offerIds));
}

export function resetModifiedOffers(offers: Offer[], products: Product[]) {
  const addedOffers = getAddedOffers();
  const addedOffersFiltered = addedOffers.filter((addedOffer) => {
    const offer = offers.find((offer) => offer.id === addedOffer.offerId);
    if (!products.length) return !!offer;
    return (
      offer &&
      products.find((product) =>
        verifyAddedVariantExistsInOffer(offer, product, addedOffer)
      )
    );
  });

  persistAddedOffersToStorage(addedOffersFiltered);
  window.dispatchEvent(new Event("offersUpdated"));
}

export async function updateCachedData(
  cart: Cart,
  offers: Offer[],
  collectionsInCart: CollectionsInCart
) {
  let addedOffers = getAddedOffers();
  const settings = getSettings();
  // Remove offers from old sessions
  let filteredAddedOffers = addedOffers
    .filter(
      (p) => p.cartToken === cart.token
      // Remove unpublished offers
    )
    .filter(
      (p) => offers.find((o) => o.id === p.offerId)
      // Remove offers that fell below threshold
    )
    .filter((addedProduct) => {
      const foundOffer: Offer | undefined = offers.find(
        (o) => o.id === addedProduct.offerId
      );
      return (
        foundOffer && offerOverThreshold(foundOffer, cart, collectionsInCart)
      );
    });

  if (settings?.use_ghost_products) {
    const deletedOffers = addedOffers.filter(
      (item) => !filteredAddedOffers.includes(item)
    );
    const variantOffersToDelete = deletedOffers
      .filter((item) => !!item?.shopifyVariantId)
      .map((item) => {
        return item?.shopifyVariantId ?? 0;
      });
    if (variantOffersToDelete.length > 0) {
      await deleteFromCart(variantOffersToDelete);
    }

    disableButtonsInCart(addedOffers);
  }

  persistAddedOffersToStorage(filteredAddedOffers);
  window.dispatchEvent(new Event("offersUpdated"));
}
