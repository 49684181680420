export function isStorageAvailable(storageType = 'localStorage') {
  try {
    let storage = window[storageType];
    const x = '__storage_test__';
    if (storage) {
      storage.setItem(x, x);
      storage.removeItem(x);
    }
    return true;
  } catch (e) {
    return false;
  }
}
