export function findNumberInNodes(
  element: Element,
  elementsWithNumber: Element[],
) {
  if (!element || !element.innerHTML) {
    return;
  }

  if (!Number.isNaN(Number(element.innerHTML))) {
    elementsWithNumber.push(element);
  }

  if (element instanceof Node) {
    Array.from((element as HTMLElement).children).forEach((child) => {
      findNumberInNodes(child, elementsWithNumber);
    });
  }
}

export function updateCustomSelectors(
  selectors: string[],
  customSelectorsVarName: string,
  onlyCustom: boolean = false,
) {
  let customSelectors = window[customSelectorsVarName] || [];
  if (onlyCustom && customSelectors.length) {
    return customSelectors;
  }
  return selectors.concat(customSelectors);
}
